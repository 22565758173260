function importAll(r) {
	let images = {};
	r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
	return images;
  }
  
const hand = importAll(require.context('./Hand', false, /\.(png|jpe?g|svg)$/));
const handBones = importAll(require.context('./Bones', false, /\.(png|jpe?g|svg)$/));
const heart = importAll(require.context('./Heart', false, /\.(png|jpe?g|svg)$/));
const heartCut = importAll(require.context('./Heart_Cut', false, /\.(png|jpe?g|svg)$/));
const head = importAll(require.context('./Head', false, /\.(png|jpe?g|svg)$/));


export default {bathroom: hand, bathroom2: handBones, garage: heart, garage2: heartCut, bedroom: head}