import	React, {useState, useEffect}	from 'react'
// import	ImageMapper						from 'react-image-mapper'

import	img_house						from '../ressources/images/Elements/house_floor.png'
import	img_house_livingroom			from '../ressources/images/Elements/house_floor_livingroom.png'
import	img_house_bathroom				from '../ressources/images/Elements/house_floor_bathroom.png'
import	img_house_bedroom				from '../ressources/images/Elements/house_floor_bedroom.png'
import	img_house_garage				from '../ressources/images/Elements/house_floor_garage.png'
import	img_house_kitchen				from '../ressources/images/Elements/house_floor_kitchen.png'

import	LANGUAGE						from '../ressources/language'

import	'./style.css'

const	HOUSE = {
	empty:		img_house,
	livingroom:	img_house_livingroom,
	bathroom:	img_house_bathroom,
	bedroom:	img_house_bedroom,
	garage:		img_house_garage,
	kitchen:	img_house_kitchen
}

function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
	  width: undefined,
	  height: undefined
	})

	useEffect(() => {
	 function handleResize() {
		setWindowSize({
		  width: window.innerWidth,
		  height: window.innerHeight,
		})
	  }

	  // Add event listener
	  window.addEventListener("resize", handleResize)

	  // Call handler right away so state gets updated with initial window size
	  handleResize()

	  // Remove event listener on cleanup
	  return () => window.removeEventListener("resize", handleResize)
	}, []) // Empty array ensures that effect is only run on mount

	return windowSize
}

function	Home(props) {
	const	size = useWindowSize()

	const	[zone, set_zone] = useState(null)
	const	[map] = useState([
		{
			name: "livingroom",
			top:	'4.5%',
			left:	'18%',
			width:	'40%',
			height:	'40%'
		},
		{
			name: "bedroom",
			top:	'4.5%',
			left:	'56%',
			width:	'40%',
			height:	'35%'
		},
		{
			name: "bathroom",
			shape: "poly",
			top:	'40%',
			left:	'65%',
			width:	'31%',
			height:	'24%'
		},
		{
			name: "garage",
			top:	'45%',
			left:	'2%',
			width:	'30%',
			height:	'40%'
		},
		{
			name: "kitchen",
			top:	'63%',
			left:	'47%',
			width:	'49%',
			height:	'33%'
		},
	])

	return (
		<div className={'homeMain'}>
			<div className={'homeContainerTitle'}>{LANGUAGE[props.lang].home.title}</div>

			<div className={'homeContainer'}>
				<div className={'homeContainerLeft'} />
				<div className={'homeContainerCenter'}>
					{(size && size.width) &&
						// <ImageMapper
						// 	src={!zone ? HOUSE.empty : HOUSE[zone]}
						// 	alt={'house'}
						// 	map={map}
						// 	// height={size.height * 0.7}
						// 	width={size.width < 768 ? size.width * 0.9 : size.width * 0.4}
						// 	imgWidth={590}
						// 	// imgHeight={510}
						// 	onMouseEnter={area => set_zone(area.name)}
						// 	onMouseLeave={() => set_zone(null)}
						// 	onClick={area => (area && area.name) && props.history.push(area.name)}
						// />
						<div style={{position: 'relative', width: size.width < 768 ? size.width * 0.9 : size.width * 0.4, height: '100%'}}>
							<img
								style={{
									zIndex:		1,
									position: 	'absolute',
									top:		0,
									left:		0,
									width: 		size.width < 768 ? size.width * 0.9 : size.width * 0.4
								}}
								src={!zone ? HOUSE.empty : HOUSE[zone]}
								alt={'house'}
							/>
							<img
								style={{
									width: 		size.width < 768 ? size.width * 0.9 : size.width * 0.4
								}}
								src={!zone ? HOUSE.empty : HOUSE[zone]}
								alt={'house'}
							/>

							{map.map((data) =>
								<div
									style={{
										cursor: 'pointer',
										position: 'absolute',
										zIndex:	2,
										top:	data.top,
										left:	data.left,
										width:	data.width,
										height:	data.height,
										backgroundColor: data.backgroundColor || 'none',
									}}
									onMouseEnter={() => set_zone(data.name)}
									onMouseLeave={() => set_zone(null)}
									onClick={() => props.history.push(data.name)}
								/>
							)}
						</div>
					}
				</div>
				<div className={'homeContainerRight'}>
					<span>{LANGUAGE[props.lang].home.select}</span>
					<span className={'homeContainerRightText'}>{LANGUAGE[props.lang].home.or}</span>
					<span>{LANGUAGE[props.lang].home.click}</span>
				</div>
			</div>

			<div className={'homeButtonContainer'}>
				<div onClick={() => props.onClickGuided()}>
					<span>{LANGUAGE[props.lang].home.button}</span>
				</div>
			</div>
		</div>
	)
}

export default Home