import	React, {useState, useEffect}		from 'react'
import { createBrowserHistory }				from "history"
import	ReactHowler 						from 'react-howler'

import	Header								from './Header'
import	Home								from './Home'
import	House								from './House'
import	Glossary							from './Glossary'
import	Contact								from './Contact'
import	Reference							from './Reference'

import	audio								from './ressources/audio'

import	'./App.css'


function	App(def) {
	function	setLocalStorage(key, value) {localStorage.setItem(key, JSON.stringify(value))}
	function	getLocalStorage(key, base) {try {return JSON.parse(localStorage.getItem(key) || base)} catch (e) {return base}}

	const	[lang, set_lang] = useState(getLocalStorage('lang', null) || 'en')
	// const	[lang, set_lang] = useState('en')
	const	[view, set_view] = useState('')
	const	[guided, set_guided] = useState(getLocalStorage('guided', null) || false)

	const	[noScroll, set_noScroll] = useState(false)

	const	[sound_button, set_sound_button] = useState(false)

	const	[next, set_next] = useState(false)

	let history = createBrowserHistory()

	useEffect(() => {
		if (window.location.hostname && window.location.hostname.includes('acrhomegaly.ca'))
			set_lang('en')
		if (window.location.hostname && window.location.hostname.includes('lamaisondelacromegalie.ca'))
			set_lang('fr')
	})

	useEffect(() => {
		const unlisten = history.listen(({ location, action }) => {
			set_view(location.pathname.replace('/', '') || 'home')
			// console.log(location.pathname.replace('/', ''))
			set_next(location.pathname.replace('/', '') || 'home')
		})
		// This function will be invoked on component unmount and will clean up
		// the event listener.
		return () => {
			unlisten()
		}
	}, [history])

	useEffect(() => set_sound_button(false), [view, lang])

	useEffect(() => {
		if (lang)
			setLocalStorage('lang', lang)
	}, [lang])

	useEffect(() => {
		setLocalStorage('guided', guided)
	}, [guided])

	useEffect(() => {
		if (view === 'home')
			set_guided(false)
	}, [view])


	useEffect(() => {
		set_view(window.location.pathname.replace('/', '') || 'home')
	}, [window.location])

	useEffect(() => {
		if (!next)
			return

		set_view(next)
		// history.push(next)
		set_next(false)
	}, [next])

	const	onClickGuided = () => {
		set_guided(true)
		history.push('livingroom')
	}

	const	setUrl = (newView) => {
		set_view(newView)
	}

	const	checkAudio = name => {
		if (!audio[lang] || !audio[lang][name])
			return
		return <ReactHowler html5 src={audio[lang][name]} playing={(sound_button && view === name)} onEnd={() => set_sound_button(false)} />
	}


	return (
			<div className={`main ${noScroll && 'noScroll'}`}>
				{checkAudio('livingroom')}
				{checkAudio('kitchen')}
				{checkAudio('bedroom')}
				{checkAudio('bathroom')}
				{checkAudio('garage')}

				<Header history={history} lang={lang} setLang={set_lang} view={view} setNoScroll={set_noScroll} />

				{view === 'home' && <Home history={history} lang={lang} onClickGuided={onClickGuided} />}

				{!next && view === 'kitchen' && <House set_next={set_next} history={history} lang={lang} view={view} guided={guided} setNoScroll={set_noScroll} setSoundButton={set_sound_button} soundButton={sound_button} />}
				{!next && view === 'livingroom' && <House set_next={set_next} history={history} lang={lang} view={view} guided={guided} setNoScroll={set_noScroll} setSoundButton={set_sound_button} soundButton={sound_button} />}
				{!next && view === 'bedroom' && <House set_next={set_next} history={history} lang={lang} view={view} guided={guided} setNoScroll={set_noScroll} setSoundButton={set_sound_button} soundButton={sound_button} />}
				{!next && view === 'garage' && <House set_next={set_next} history={history} lang={lang} view={view} guided={guided} setNoScroll={set_noScroll} setSoundButton={set_sound_button} soundButton={sound_button} />}
				{!next && view === 'bathroom' && <House set_next={set_next} history={history} lang={lang} view={view} guided={guided} setNoScroll={set_noScroll} setSoundButton={set_sound_button} soundButton={sound_button} />}

				{view === 'glossary' && <Glossary history={history} lang={lang} />}
				{view === 'contact' && <Contact history={history} lang={lang} />}
				{view === 'reference' && <Reference history={history} lang={lang} />}
			</div>
	)
}

export default App
