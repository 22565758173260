import	React, {useState, useEffect}	from 'react'

import	img_logo						from '../ressources/images/Elements/Acromegaly Canada Logo.png'
import	img_fb							from '../ressources/images/Elements/facebook_button.png'
import	img_instagram					from '../ressources/images/Elements/instagram_button.png'
import	img_twitter						from '../ressources/images/Elements/twitter_button.png'
import	img_pfizer						from '../ressources/images/Elements/Pfizer_Logo_Color_RGB.png'
import	img_ipsen						from '../ressources/images/Elements/Ipsen_logo.png'
import	img_crinetics					from '../ressources/images/Elements/Crinetics_Logo.png'

import	LANGUAGE						from '../ressources/language'

import	'./style.css'

function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined
	})
	
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		window.addEventListener("resize", handleResize)
		handleResize()
		
		return () => window.removeEventListener("resize", handleResize)
	}, [])
	
	return windowSize
}

function	Glossary(props) {
	const	size = useWindowSize()

	return (
		<div className={'contactMain'} style={document.getElementById('header') ? {height: size.height - document.getElementById('header').offsetHeight} : null}>
			<div className={'contactTitle'}>{LANGUAGE[props.lang].contact.title}</div>

			<div>
				<div className={'contactContainer'}>
					<div className={'contactLogo'}>
						<img src={img_logo} />
					</div>
					<div className={'contactContainerText'}>
						<div>
							{LANGUAGE[props.lang].contact.text}
						</div>
						<div>
							<a href={'https://www.facebook.com/acromegalycanada'} target={'_blank'} ><img src={img_fb} alt={'facebook'} /></a>
							<a href={'https://www.instagram.com/acromegalycanada'} target={'_blank'} ><img src={img_instagram} alt={'instagram'} /></a>
							<a href={'https://www.twitter.com/AcromegalyCAN'} target={'_blank'} ><img src={img_twitter} alt={'twitter'} /></a>
						</div>
					</div>
				</div>

				<div className={'contactTextThanks'}>
					{LANGUAGE[props.lang].contact.thanks}
				</div>

				<div className={'contactContainerLogo'}>
					<div><img src={img_pfizer} alt={'pfizer'} /></div>
					<div><img src={img_ipsen} alt={'ipsen'} /></div>
					<div><img src={img_crinetics} alt={'crinetics'} /></div>
				</div>
			</div>
		</div>
	)
}

export default Glossary