import	bathroom_en		from './en/bathroom_en.wav'
import	bedroom_en		from './en/bedroom_en.wav'
import	garage_en		from './en/garage_en.wav'
import	kitchen_en		from './en/kitchen_en.wav'
import	livingroom_en	from './en/livingroom_en.wav'

import	bathroom_fr		from './fr/bathroom_fr.wav'
import	bedroom_fr		from './fr/bedroom_fr.wav'
import	garage_fr		from './fr/garage_fr.wav'
import	kitchen_fr		from './fr/kitchen_fr.wav'
import	livingroom_fr	from './fr/livingroom_fr.wav'

const	en = {
	bathroom:		bathroom_en,
	bedroom:		bedroom_en,
	garage:			garage_en,
	kitchen:		kitchen_en,
	livingroom:		livingroom_en,
}

const	fr = {
	bathroom:		bathroom_fr,
	bedroom:		bedroom_fr,
	garage:			garage_fr,
	kitchen:		kitchen_fr,
	livingroom:		livingroom_fr,
}

const	audio = {fr, en}

export default audio