import	React, {useState, useEffect}		from 'react'
import	ImageMapper							from 'react-image-mapper'

import	img_background_livingroom			from '../ressources/images/Backgrounds/livingroom_no_objects.png'
import	img_background_kitchen				from '../ressources/images/Backgrounds/kitchen_no_objects_v2.png'
import	img_background_bedroom				from '../ressources/images/Backgrounds/bedroom_no_objects_v2.png'
import	img_background_bathroom				from '../ressources/images/Backgrounds/bathroom_no_objects_v2.png'
import	img_background_garage				from '../ressources/images/Backgrounds/garage_no_objects_v2.png'

import	img_close							from '../ressources/images/Elements/close_button.png'
import	img_close_mobile					from '../ressources/images/Elements/btn-close.png'
import	img_download						from '../ressources/images/Elements/download_button.png'
import	img_arrow_button_left				from '../ressources/images/Elements/backpage_button.png'
import	img_arrow_button_right				from '../ressources/images/Elements/frontpage_button.png'
import	img_brain_en						from '../ressources/images/Elements/image_brain.png'
import	img_brain_fr						from '../ressources/images/Elements/image_brain_fr.png'
import	img_phone_green						from '../ressources/images/Elements/phone_green.png'
import	img_phone_red						from '../ressources/images/Elements/phone_red.png'

import	img_livingroom_brochure_front		from '../ressources/images/Elements/brochure_front.png'
import	img_livingroom_brochure_back		from '../ressources/images/Elements/brochure_back.png'
import	img_livingroom_brochure_front_fr	from '../ressources/images/Elements/brochure_front_fr.png'
import	img_livingroom_brochure_back_fr		from '../ressources/images/Elements/brochure_back_fr.png'
import	img_livingroom_remote_tv			from '../ressources/images/Objects/tv_popup.png'
import	img_livingroom_waterBottle			from '../ressources/images/Objects/water_bottle_popup.png'
import	img_livingroom_book_en				from '../ressources/images/Objects/magazine_popup_en.png'
import	img_livingroom_book_fr				from '../ressources/images/Objects/magazine_popup_fr.png'
import	img_livingroom_rubikcube			from '../ressources/images/Objects/rubikcube_popup.png'

import	img_livingroom_object_brochure		from '../ressources/images/Objects/brochure_object.png'
import	img_livingroom_object_remote		from '../ressources/images/Objects/remote_object.png'
import	img_livingroom_object_book			from '../ressources/images/Objects/magazine_object.png'
import	img_livingroom_object_rubikcube		from '../ressources/images/Objects/rubikcube_object.png'
import	img_livingroom_object_waterBottle	from '../ressources/images/Objects/water_bottle_object.png'
import	img_livingroom_hover_brochure		from '../ressources/images/Objects_hover/brochure.png'
import	img_livingroom_hover_remote			from '../ressources/images/Objects_hover/remote.png'
import	img_livingroom_hover_book			from '../ressources/images/Objects_hover/book.png'
import	img_livingroom_hover_rubikcube		from '../ressources/images/Objects_hover/rubikcube.png'
import	img_livingroom_hover_waterBottle	from '../ressources/images/Objects_hover/water_bottle.png'


import	img_kitchen_water					from '../ressources/images/Objects/glass_water_popup.png'
import	img_kitchen_pill					from '../ressources/images/Objects/pillbottle_popup.png'
import	img_kitchen_pillbox_en				from '../ressources/images/Objects/pillbox_english_popup.png'
import	img_kitchen_pillbox_fr				from '../ressources/images/Objects/pillbox_french_popup.png'
import	img_kitchen_knife					from '../ressources/images/Objects/knife_popup.png'
import	img_kitchen_postIt_en				from '../ressources/images/Objects/postIt_english_popup.png'
import	img_kitchen_postIt_fr				from '../ressources/images/Objects/postIt_french_popup.png'

import	img_kitchen_object_water			from '../ressources/images/Objects/glass_water_object.png'
import	img_kitchen_object_pill				from '../ressources/images/Objects/pillbottle_object.png'
// import	img_kitchen_object_pillbox			from '../ressources/images/Objects/pillbox_english_object.png'
import	img_kitchen_object_pillbox			from '../ressources/images/Objects/pillbox_french_object.png'
import	img_kitchen_object_knife			from '../ressources/images/Objects/knife_object.png'
import	img_kitchen_object_postIt_en		from '../ressources/images/Objects/postIt_english_object.png'
// import	img_kitchen_object_postIt_fr		from '../ressources/images/Objects/postIt_french_object.png'
import	img_kitchen_hover_water				from '../ressources/images/Objects_hover/water_v2.png'
import	img_kitchen_hover_pill				from '../ressources/images/Objects_hover/pill.png'
import	img_kitchen_hover_pillbox			from '../ressources/images/Objects_hover/pillbox.png'
import	img_kitchen_hover_knife				from '../ressources/images/Objects_hover/knife.png'
import	img_kitchen_hover_postIt			from '../ressources/images/Objects_hover/postIt_en.png'


import	img_bedroom_mirror					from '../ressources/images/Objects/mirror_popup.png'
import	img_bedroom_rings					from '../ressources/images/Objects/rings_popup.png'
import	img_bedroom_pillow					from '../ressources/images/Objects/pillow_popup.png'
import	img_bedroom_boxPaper				from '../ressources/images/Objects/box_of_paper_popup.png'
import	img_bedroom_bed						from '../ressources/images/Objects/bed_popup.png'

import	img_bedroom_object_mirror			from '../ressources/images/Objects/mirror_object.png'
import	img_bedroom_object_rings			from '../ressources/images/Objects/ring_object.png'
import	img_bedroom_object_pillow			from '../ressources/images/Objects/pillow_object_v2.png'
import	img_bedroom_object_bed				from '../ressources/images/Objects/bed_object_v2.png'
import	img_bedroom_object_boxPaper			from '../ressources/images/Objects/box_of_paper_object.png'
import	img_bedroom_hover_mirror			from '../ressources/images/Objects_hover/mirror.png'
import	img_bedroom_hover_rings				from '../ressources/images/Objects_hover/rings.png'
import	img_bedroom_hover_pillow			from '../ressources/images/Objects_hover/pillow.png'
import	img_bedroom_hover_bed				from '../ressources/images/Objects_hover/bed_v2.png'
import	img_bedroom_hover_boxPaper			from '../ressources/images/Objects_hover/box_of_paper.png'


import	img_bathroom_hairbrush				from '../ressources/images/Objects/hairbrush_popup.png'
import	img_bathroom_toothbrush				from '../ressources/images/Objects/toothbrush_popup.png'
import	img_bathroom_weightScale			from '../ressources/images/Objects/weight_scale_popup.png'
import	img_bathroom_toiletBag				from '../ressources/images/Objects/toilet_bag_popup.png'
import	img_bathroom_facialCream			from '../ressources/images/Objects/facial_cream_popup.png'

import	img_bathroom_object_hairbrush		from '../ressources/images/Objects/hairbrush_object_v2.png'
import	img_bathroom_object_toothbrush		from '../ressources/images/Objects/toothbrush_object.png'
import	img_bathroom_object_weightScale		from '../ressources/images/Objects/weight_scale_object.png'
import	img_bathroom_object_toiletBag		from '../ressources/images/Objects/toilet_bag_object.png'
import	img_bathroom_object_facialCream		from '../ressources/images/Objects/facial_cream_object.png'
import	img_bathroom_hover_hairbrush		from '../ressources/images/Objects_hover/hairbrush.png'
import	img_bathroom_hover_toothbrush		from '../ressources/images/Objects_hover/toothbrush.png'
import	img_bathroom_hover_weightScale		from '../ressources/images/Objects_hover/weightScale.png'
import	img_bathroom_hover_toiletBag		from '../ressources/images/Objects_hover/toilet_bag.png'
import	img_bathroom_hover_facialCream		from '../ressources/images/Objects_hover/facial_cream.png'


import	img_garage_bicycle					from '../ressources/images/Objects/bicycle_popup.png'
import	img_garage_microphone				from '../ressources/images/Objects/microphone_popup.png'
import	img_garage_shoes					from '../ressources/images/Objects/shoes_popup.png'
import	img_garage_weight					from '../ressources/images/Objects/weight_popup.png'
import	img_garage_knee						from '../ressources/images/Objects/knee_popup.png'

import	img_garage_object_bicycle			from '../ressources/images/Objects/bicycle_object_v2.png'
import	img_garage_object_microphone		from '../ressources/images/Objects/microphone_object_v2.png'
import	img_garage_object_shoes				from '../ressources/images/Objects/shoes_object.png'
import	img_garage_object_weight			from '../ressources/images/Objects/weight_object.png'
import	img_garage_object_knee				from '../ressources/images/Objects/knee_object.png'
import	img_garage_hover_bicycle			from '../ressources/images/Objects_hover/bicycle.png'
import	img_garage_hover_microphone			from '../ressources/images/Objects_hover/microphone.png'
import	img_garage_hover_shoes				from '../ressources/images/Objects_hover/shoes.png'
import	img_garage_hover_weight				from '../ressources/images/Objects_hover/weight.png'
import	img_garage_hover_knee				from '../ressources/images/Objects_hover/knee.png'


import	img_phone							from '../ressources/images/Elements/character_talking_rooms.png'
import	img_phone_mobile					from '../ressources/images/Elements/phone-mobile.png'

import	img_swipe							from '../ressources/images/Elements/swipe.png'

import	img_body							from '../ressources/images/Body/Fond.jpg'
import	img_zoom_in							from '../ressources/images/Elements/zoomin_button.png'
import	img_zoom_out						from '../ressources/images/Elements/zoomout_button.png'

import	img_logo							from '../ressources/images/Elements/Acromegaly Canada Logo.png'
import	img_brochure1						from '../ressources/images/Elements/brochure1.png'
import	img_brochure2						from '../ressources/images/Elements/brochure2.png'

import	img_brochure_en_1					from '../ressources/images/Elements/Brochure/brochure_mobile_en_1.png'
import	img_brochure_en_2					from '../ressources/images/Elements/Brochure/brochure_mobile_en_2.png'
import	img_brochure_en_3					from '../ressources/images/Elements/Brochure/brochure_mobile_en_3.png'
import	img_brochure_en_4					from '../ressources/images/Elements/Brochure/brochure_mobile_en_4.png'
import	img_brochure_en_5					from '../ressources/images/Elements/Brochure/brochure_mobile_en_5.png'
import	img_brochure_en_6					from '../ressources/images/Elements/Brochure/brochure_mobile_en_6.png'
import	img_brochure_fr_1					from '../ressources/images/Elements/Brochure/brochure_mobile_fr_1.png'
import	img_brochure_fr_2					from '../ressources/images/Elements/Brochure/brochure_mobile_fr_2.png'
import	img_brochure_fr_3					from '../ressources/images/Elements/Brochure/brochure_mobile_fr_3.png'
import	img_brochure_fr_4					from '../ressources/images/Elements/Brochure/brochure_mobile_fr_4.png'
import	img_brochure_fr_5					from '../ressources/images/Elements/Brochure/brochure_mobile_fr_5.png'
import	img_brochure_fr_6					from '../ressources/images/Elements/Brochure/brochure_mobile_fr_6.png'

import	gif_heart							from '../ressources/images/Body/Coeur_700b.gif'
import	gif_harm							from '../ressources/images/Body/Veines_700b.gif'
import	gif_head							from '../ressources/images/Body/head.gif'

import	annimation							from '../ressources/annimation/'

import	LANGUAGE							from '../ressources/language'

import	'./style.css'


function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined
	})

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})

			setTimeout(
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				})
			, 1000)
		}

		window.addEventListener("resize", handleResize)
		handleResize()

		return () => window.removeEventListener("resize", handleResize)
	}, [])

	return windowSize
}


const	HOUSE = {
	livingroom: {
		background:		img_background_livingroom,
		items: [
			{name: 'book', bottom: 0.18, top: 0.67, left: 0.36, img: img_livingroom_object_book, hover: img_livingroom_hover_book},
			{name: 'brochure', bottom: 0.187, top: 0.665, left: 0.485, img: img_livingroom_object_brochure, hover: img_livingroom_hover_brochure},
			{name: 'remote', bottom: 0.265, top: 0.586, left: 0.64, img: img_livingroom_object_remote, hover: img_livingroom_hover_remote},
			{name: 'rubikcube', bottom: 0.265, top: 0.307, left: 0.768, img: img_livingroom_object_rubikcube, hover: img_livingroom_hover_rubikcube},
			{name: 'waterBottle', bottom: 0.1, top: 0.63, left: 0.18, width: 0.15, height: 0.22, img: img_livingroom_object_waterBottle, hover: img_livingroom_hover_waterBottle}
		]
	},
	kitchen: {
		background:		img_background_kitchen,
		items: [
			{name: 'water', bottom: 0.35, top: 0.5, left: 0.8, img: img_kitchen_object_water, hover: img_kitchen_hover_water},
			{name: 'pill', bottom: 0.35, top: 0.654, left: 0.36, img: img_kitchen_object_pill, hover: img_kitchen_hover_pill},
			{name: 'pillbox', bottom: 0.199, top: 0.654, left: 0.51, img: img_kitchen_object_pillbox, hover: img_kitchen_hover_pillbox},
			{name: 'knife', bottom: 0.199, top: 0.49, left: 0.6, width: 0.12, height: 0.19, img: img_kitchen_object_knife, hover: img_kitchen_hover_knife},
			{name: 'postIt', bottom: 0.199, top: 0.32, left: 0.13, img: {en: img_kitchen_object_postIt_en, fr: img_kitchen_object_postIt_en}, hover: img_kitchen_hover_postIt}
		]
	},
	bedroom: {
		background:		img_background_bedroom,
		items: [
			{name: 'mirror', bottom: 0.15, top: 0.388, left: 0.15, width: 0.145, height: 0.454, img: img_bedroom_object_mirror, hover: img_bedroom_hover_mirror},
			{name: 'rings', bottom: 0.275, top: 0.576, left: 0.023, img: img_bedroom_object_rings, hover: img_bedroom_hover_rings},
			{name: 'bed', bottom: 0.242, top: 0.57, left: 0.4, width: 0.46, height: 0.4, img: img_bedroom_object_bed, hover: img_bedroom_hover_bed},
			{name: 'pillow', bottom: 0.242, top: 0.588, left: 0.759, width: 0.15, height: 0.17, img: img_bedroom_object_pillow, hover: img_bedroom_hover_pillow},
			{name: 'boxPaper', bottom: 0.242, top: 0.55, left: 0.55, width: 0.13, height: 0.2, img: img_bedroom_object_boxPaper, hover: img_bedroom_hover_boxPaper},
		]
	},
	bathroom: {
		background:		img_background_bathroom,
		items: [
			{name: 'hairbrush', bottom: 0.388, top: 0.462, left: 0.59, img: img_bathroom_object_hairbrush, hover: img_bathroom_hover_hairbrush},
			{name: 'toothbrush', bottom: 0.3958, top: 0.457, left: 0.46, img: img_bathroom_object_toothbrush, hover: img_bathroom_hover_toothbrush},
			{name: 'weightScale', bottom: 0.148, top: 0.7, left: 0.663, img: img_bathroom_object_weightScale, hover: img_bathroom_hover_weightScale},
			{name: 'toiletBag', bottom: 0.148, top: 0.45, left: 0.34, img: img_bathroom_object_toiletBag, hover: img_bathroom_hover_toiletBag},
			{name: 'facialCream', bottom: 0.148, top: 0.40, left: 0.21, img: img_bathroom_object_facialCream, hover: img_bathroom_hover_facialCream},
		]
	},
	garage:	 {
		background:		img_background_garage,
		items: [
			{name: 'bicycle', bottom: 0.17, top: 0.4935, left: 0.498, width: 0.2574, height: 0.3168, img: img_garage_object_bicycle, hover: img_garage_hover_bicycle},
			{name: 'microphone', bottom: 0.50, top: 0.347, left: 0.142, img: img_garage_object_microphone, hover: img_garage_hover_microphone},
			{name: 'shoes', bottom: 0.12, top: 0.72, left: 0.22, img: img_garage_object_shoes, hover: img_garage_hover_shoes},
			{name: 'weigh', bottom: 0.12, top: 0.72, left: 0.37, img: img_garage_object_weight, hover: img_garage_hover_weight},
			{name: 'knee', bottom: 0.12, top: 0.29, left: 0.49, img: img_garage_object_knee, hover: img_garage_hover_knee}
		]
	},
}

const	img_livingroom_book = {
	en:		img_livingroom_book_en,
	fr:		img_livingroom_book_fr,
}

const	img_kitchen_pillbox = {
	en:		img_kitchen_pillbox_en,
	fr:		img_kitchen_pillbox_fr,
}

const	img_kitchen_postIt = {
	en:		img_kitchen_postIt_en,
	fr:		img_kitchen_postIt_fr
}

const	img_brochure = {
	en: {
		1:	img_brochure_en_1,
		2:	img_brochure_en_2,
		3:	img_brochure_en_3,
		4:	img_brochure_en_4,
		5:	img_brochure_en_5,
		6:	img_brochure_en_6,
	},
	fr: {
		1:	img_brochure_fr_1,
		2:	img_brochure_fr_2,
		3:	img_brochure_fr_3,
		4:	img_brochure_fr_4,
		5:	img_brochure_fr_5,
		6:	img_brochure_fr_6,
	},
}


function	House(props) {
	const	canvasRef = React.useRef(null)
	const	size = useWindowSize()

	const	[modal, set_modal] = useState(false)
	const	[count, set_count] = useState(0)
	const	[finalCount, set_finalCount] = useState(0)
	const	[hover, set_hover] = useState(false)

	const	[load, set_load] = useState(false)
	const	[swipe, set_swipe] = useState(false)
	const	[phone, set_phone] = useState(true)

	const	[arrowButton, set_arrowButton] = useState('left')

	const	[currentImage, setCurrImg] = useState(0);
	const	[images, set_images] = useState([])
	const	[images2, set_images2] = useState([])
	const	[switchAnim, set_switchAnim] = useState(false)
	const	[pause, set_pause] = useState(false)
	const	[zoom, set_zoom] = useState(false)
	const	[draw, set_draw] = useState(false)

	const	[forceReload, set_forceReload] = useState(0)

	useEffect(() => set_phone(true), [props.lang, props.view])
	useEffect(() => set_modal(false), [props.view])

	useEffect(() => {
		set_arrowButton(size.width < 768 ? 'right' : 'left')

		set_count(0)
		set_finalCount(0)
		setCurrImg(0)
		set_switchAnim(false)

		if (modal === props.view && annimation[props.view]) {
			set_images(Object.values(annimation[props.view]))

			if (annimation[`${props.view}2`])
				set_images2(Object.values(annimation[`${props.view}2`]))
		} else {
			set_images([])
			set_images2([])
		}

		const body = document.body
		body.style.position = modal ? 'fixed' : ''

	}, [modal])

	useEffect(() => {
		if (zoom && !draw)
			return setTimeout(() => set_draw(true), 500)
		else if (!zoom && draw)
			set_draw(false)
	}, [zoom])

	useEffect(() => {
		if ((finalCount == currentImage) || (props.view === 'bedroom' && pause))
			return

		if (props.view !== 'bedroom' && pause)
			set_pause(false)

		let interval = setInterval(() => {
			if (currentImage > finalCount) {
				setCurrImg((currentImage - 10 > finalCount && props.view !== 'bedroom') ? currentImage - 10 : currentImage - 1)
			} else {
				setCurrImg((currentImage + 10 < finalCount && props.view !== 'bedroom') ? currentImage + 10 : currentImage + 1)
			}

		//   if (currentImage < images.length - 1) {
		// 	setCurrImg((prev) => prev + 1);
		//   } else {
		// 	setCurrImg(0);
		//   }
		}, props.view === 'bedroom' ? 25 : 1);
		return () => clearInterval(interval);
	}, [currentImage, finalCount, pause]);

	useEffect(() => {
		if (props.view !== 'bedroom' || finalCount > currentImage)
			return

		setCurrImg(0)
		set_finalCount(images.length)
	}, [props.view, images, currentImage])

	// if (!load) {
	// 	setTimeout(() => set_load(true), 100)
	// 	console.log('ICI')
	// 	return <div />
	// }

	function nextRoom() {
		if (props.view === 'livingroom')
			return props.history.push('kitchen')
		if (props.view === 'kitchen')
			return props.history.push('bedroom')
		if (props.view === 'bedroom')
			return props.history.push('bathroom')
		if (props.view === 'bathroom')
			return props.history.push('garage')
		// if (props.view === 'livingroom')
		// 	return props.set_next('kitchen')
		// if (props.view === 'kitchen')
		// 	return props.set_next('bedroom')
		// if (props.view === 'bedroom')
		// 	return props.set_next('bathroom')
		// if (props.view === 'bathroom')
		// 	return props.set_next('garage')
	}

	function onClickPhone() {
		if (props.soundButton)
			return

		set_phone(false)
		props.setSoundButton(true)
	}

	function renderModalItem() {
		let	render = (
			<div className={`houseModaAnnimatedContainer ${modal === props.view && 'actual'}`}>
				{(props.view !== 'kitchen') &&
					<div className={`houseModaAnnimated ${(switchAnim && props.view === 'bathroom') && 'slider'}`} onClick={() => props.view === 'bedroom' ? set_pause(!pause) : null}>
						<div className="flip-book">
							<img
								alt="preview"
								src={images[0]}
								className={count >= images.length ? "preview done" : "preview"}
							/>

							{images.map((image, index) => (
								<img
									alt="movement"
									key={index}
									className={currentImage === index ? "active" : ""}
									src={image}
									onLoad={() => set_count((prev) => prev + 1)}
								/>
							))}

							{images2.map((image, index) => (
								<img
									alt="movement"
									key={`2-${index}`}
									className={(currentImage === index && switchAnim) ? "active" : ""}
									src={image}
								/>
							))}

							{(props.view === 'bathroom' || props.view === 'garage') &&
								<div onClick={() => set_switchAnim(!switchAnim)}>
									<span>{!switchAnim ? LANGUAGE[props.lang].house.button.switch[props.view] : LANGUAGE[props.lang].house.button.switch.default}</span>
								</div>
							}
						</div>

						{props.view !== 'bedroom' && <input type={'range'} min={0} max={images.length - 1} value={finalCount} onChange={val => set_finalCount(val.target.value)} />}
					</div>
				}
				{(props.view === 'kitchen') &&
					<div className={`houseModaAnnimatedKitchen ${zoom}`}>
						<div style={{backgroundImage: `url(${img_body})`}} />

						{/* {(zoom && draw) && <div className={'houseModaAnnimatedKitchenImage'} style={{backgroundImage: `url(${zoom === 'heart' ? gif_heart : gif_harm})`}} />} */}
						<div className={`houseModaAnnimatedKitchenImage ${(zoom === 'heart' && draw) && 'active'}`} style={{backgroundImage: `url(${gif_heart})`}} />
						<div className={`houseModaAnnimatedKitchenImage ${(zoom === 'harm' && draw) && 'active'}`} style={{backgroundImage: `url(${gif_harm})`}} />

						{!zoom && <div className={'houseModaAnnimatedKitchenZoom1'} onClick={() => set_zoom('heart')}><img src={img_zoom_in} alt={'zoom'} /></div>}
						{!zoom && <div className={'houseModaAnnimatedKitchenZoom2'} onClick={() => set_zoom('harm')}><img src={img_zoom_in} alt={'zoom'} /></div>}
						{zoom && <div className={'houseModaAnnimatedKitchenZoomOut'} onClick={() => set_zoom(false)}><img src={img_zoom_out} alt={'zoom'} /></div>}
					</div>
				}
				{/* {(props.view === 'bedroom') &&
					<div className={`houseModaAnnimatedKitchen`}>
						<div className={`houseModaAnnimatedKitchenImage active`} style={{backgroundImage: `url(${gif_head})`}} />
					</div>
				} */}
				<div className={`houseModaAnnimatedText`}>
					{(zoom && draw) ?
						LANGUAGE[props.lang].house.animated[`${modal}_${zoom}`] || LANGUAGE[props.lang].house.animated[modal]
						:
						LANGUAGE[props.lang].house.animated[modal]
					}
				</div>
			</div>
		)

		if (modal === 'livingroom_brochure') {
			if (size.width < 768) {
				const split = arrowButton.split('-')
				const page = split[1] ? parseInt(split[1]) + 1 : 1

				render = (
					<div className={'houseModalLivingRoomBrochure'}>
						<div className={`houseModalGlobalText`}>
							<div className={'right-1'}>
								<img src={img_brochure[props.lang][page]} alt={'brochure'} />
							</div>
						</div>
						<div className={`houseModalGlobalBottom`}>
							<div>
								<img src={page < 6 ? img_arrow_button_right : img_arrow_button_left} alt={'arrowButton'} onClick={() => set_arrowButton(page === 6 ? 'right' : `right-${page}`)} />
							</div>
							<div className={`houseModalGlobalBottomText`}>
								<a href={`Acromegaly Canada Brochure 2021 ${props.lang.toUpperCase()}.pdf`} download>
									{LANGUAGE[props.lang].house.item[modal].download}
								</a>
							</div>
							<div className={'houseModalClose relative'}>
								<img src={img_close} alt={'close'} onClick={() => set_modal(null)} />
							</div>
						</div>
					</div>
				)
			} else {
				render = (
					<div className={'houseModalLivingRoomBrochure'}>
						<div className={'houseModalLivingRoomBrochureButton'}>
							<div>
								<img src={arrowButton === 'left' ? img_arrow_button_left : img_arrow_button_right} alt={'arrowButton'} onClick={() => set_arrowButton(arrowButton === 'left' ? 'right' : 'left')} />
							</div>
						</div>
						{props.lang === 'fr' ?
							<div className={'houseModalLivingRoomBrochureContainer'} style={{backgroundImage: `url(${arrowButton === 'left' ? img_livingroom_brochure_front_fr : img_livingroom_brochure_back_fr})`}} />
							:
							<div className={'houseModalLivingRoomBrochureContainer'} style={{backgroundImage: `url(${arrowButton === 'left' ? img_livingroom_brochure_front : img_livingroom_brochure_back})`}} />
						}
						<div className={'houseModalLivingRoomBrochureButton'}>
							<div>
								<a href={`Acromegaly Canada Brochure 2021 ${props.lang.toUpperCase()}.pdf`} download>
									<img src={img_download} alt={'download'} />
								</a>
							</div>
						</div>
					</div>
				)
			}
		} else if (modal !== props.view) {
			let	src = img_livingroom_book[props.lang]

			if (modal === 'livingroom_remote') src = img_livingroom_remote_tv
			if (modal === 'livingroom_rubikcube') src = img_livingroom_rubikcube
			if (modal === 'livingroom_waterBottle') src = img_livingroom_waterBottle
			if (modal === 'kitchen_water') src = img_kitchen_water
			if (modal === 'kitchen_pill') src = img_kitchen_pill
			if (modal === 'kitchen_pillbox') src = img_kitchen_pillbox[props.lang]
			if (modal === 'kitchen_knife') src = img_kitchen_knife
			if (modal === 'kitchen_postIt') src= img_kitchen_postIt[props.lang]
			if (modal === 'bedroom_mirror') src = img_bedroom_mirror
			if (modal === 'bedroom_rings') src = img_bedroom_rings
			if (modal === 'bedroom_pillow') src = img_bedroom_pillow
			if (modal === 'bedroom_bed') src = img_bedroom_bed
			if (modal === 'bedroom_boxPaper') src = img_bedroom_boxPaper
			if (modal === 'bathroom_hairbrush') src = img_bathroom_hairbrush
			if (modal === 'bathroom_toothbrush') src = img_bathroom_toothbrush
			if (modal === 'bathroom_weightScale') src = img_bathroom_weightScale
			if (modal === 'bathroom_toiletBag') src = img_bathroom_toiletBag
			if (modal === 'bathroom_facialCream') src = img_bathroom_facialCream
			if (modal === 'garage_bicycle') src = img_garage_bicycle
			if (modal === 'garage_microphone') src = img_garage_microphone
			if (modal === 'garage_shoes') src = img_garage_shoes
			if (modal === 'garage_weigh') src = img_garage_weight
			if (modal === 'garage_knee') src = img_garage_knee

			render = (
				<div className={'houseModalGlobal'}>
					<div className={'houseModalGlobalImage'}>
						<div>
							<img src={src} alt={modal} />
						</div>
						<img src={src} alt={modal} />
					</div>
					<div className={`houseModalGlobalText ${(modal !== 'livingroom_remote' && modal !== 'livingroom_book') && 'center'}`}>
						<div>
							{LANGUAGE[props.lang].house.item[modal]}
							{modal === 'livingroom_book' && <div className={'houseModalGlobalTextImage'}><img src={props.lang === 'en' ? img_brain_en : img_brain_fr} alt={'brain'} /></div>}
						</div>
					</div>
				</div>
			)
		}

		return (
			<div className={`houseModal ${modal && 'show'}`} style={{height: size.height - document.getElementById('header').offsetHeight}}>
				<div onClick={e => e.stopPropagation()}>
					{render}

					{(!isPhone || modal !== 'livingroom_brochure') &&
						<div className={'houseModalClose'}>
							<img src={img_close} alt={'close'} onClick={() => set_modal(null)} />
						</div>
					}
				</div>
			</div>
		)
	}

	function getObjectFitSize(contains /* true = contain, false = cover */, containerWidth, containerHeight, width, height){
		var doRatio = width / height;
		var cRatio = containerWidth / containerHeight;
		var targetWidth = 0;
		var targetHeight = 0;
		var test = contains ? (doRatio > cRatio) : (doRatio < cRatio);

		if (test) {
			targetWidth = containerWidth;
			targetHeight = targetWidth / doRatio;
		} else {
			targetHeight = containerHeight;
			targetWidth = targetHeight * doRatio;
		}

		return {
			width: targetWidth,
			height: targetHeight,
			x: (containerWidth - targetWidth) / 2,
			y: (containerHeight - targetHeight) / 2
		};
	}

	const isPhone = size.width <= 768
	let sizeContainer =  {
		height:	0,
		width:	0,
		top:	0,
		left:	0,
	}
	let	isLoad = false
	let style = {}


	if (document.getElementById('imageContainer') && document.getElementById('image')) {
		const	img = document.getElementById('image')
		sizeContainer =  {
			height:	img.offsetHeight,
			width:	img.offsetWidth,
			left:	img.offsetLeft,
			top:	img.offsetTop
		}

		const	calculPosition = getObjectFitSize(true, img.width, img.height, img.naturalWidth, img.naturalHeight)

		sizeContainer.left += calculPosition.x
		sizeContainer.width = calculPosition.width
	}

	if (document.getElementById('header') && document.getElementById('houseButtonContainerPhone')) {
		if (isPhone)
			style = {height: size.height - document.getElementById('header').offsetHeight - document.getElementById('houseButtonContainerPhone').offsetHeight}
		else
			style = {maxHeight: size.height - document.getElementById('header').offsetHeight}

		isLoad = true
	}

	if (isPhone && forceReload > 0 && sizeContainer.height !== style.height)
		setTimeout(() => set_forceReload(forceReload + 1), 100)

	// console.log(style)

	return (
		<div className={'houseMain'}>
			{/* {(isLoad && (style.height || !isPhone)) && */}
				<div id={'imageContainer'} className={'houseMainImage'} style={style}>
					{(isLoad && (style.height || !isPhone)) && <img id={'image'} src={HOUSE[props.view].background} onLoad={(e) => {
						setTimeout(() => set_forceReload(forceReload + 1), 100)}
					}/>}

					{(!swipe) &&
						<div className={'houseSlideTuto'} style={sizeContainer} onTouchMove={() => set_swipe(true)}>
							<div>
								<div>
									<img className={'houseSwipe img1'} src={img_swipe} />
									<img className={'houseSwipe img2'} src={img_swipe} />
									<img className={'houseSwipe img3'} src={img_swipe} />
									<img className={'houseSwipe img4'} src={img_swipe} />
								</div>
								<div>
									<span>{LANGUAGE[props.lang].house.swipe}</span>
								</div>
							</div>
						</div>
					}

					{(forceReload > 0) && HOUSE[props.view].items.map((item, i) =>
						<div
							key={`item-${item.name}`}
							className={`item-${item.name} itemGlobal ${(isPhone || hover !== item.name) && 'draw'}`}
							onMouseLeave={() => set_hover(false)}
							onMouseEnter={() => set_hover(item.name)}
							onClick={() => set_modal(`${props.view}_${item.name}`)}
							style={{
								left: item.left ? (sizeContainer.width * item.left) + sizeContainer.left : null,
								width: sizeContainer.width * (item.width || 0.0875),
								// bottom: sizeContainer.height * (item.bottom + 0.007),
								top: item.top ? (sizeContainer.height * item.top) + sizeContainer.top : null,
								height: sizeContainer.height * (item.height || 0.141),
								backgroundImage: `url("${item.img[props.lang] || item.img}")`,
								zIndex: i
							}} />
						)
					}
					{(forceReload > 0) && HOUSE[props.view].items.map((item, i) =>
						<div
							key={`item-${item.name}-hover`}
							className={`item-${item.name} itemGlobal ${(!isPhone && hover === item.name) && 'draw'}`}
							// className={`item-${item.name} itemGlobal draw`}
							onMouseLeave={() => set_hover(false)}
							onMouseEnter={() => set_hover(item.name)}
							onClick={() => set_modal(`${props.view}_${item.name}`)}
							style={{
								left: item.left ? (sizeContainer.width * item.left) + sizeContainer.left : null,
								width: sizeContainer.width * (item.width || 0.0875),
								top: item.top ? (sizeContainer.height * item.top) + sizeContainer.top : null,
								height: sizeContainer.height * (item.height || 0.141),
								backgroundImage: `url("${item.hover[props.lang] || item.hover}")`,
								zIndex: i
							}} />
						)
					}

					{sizeContainer.height > 0 &&
						<div className={'housePhone'} style={{marginLeft: sizeContainer.left}}>
							<div><img src={img_phone} alt={'phone'} /></div>
							<div className={`housePhoneGreen ${phone && 'on'}`} style={!props.soundButton ? {cursor: 'pointer'} : {}} onClick={onClickPhone}>
								<img src={img_phone_green} />
							</div>
							<div className={`housePhoneRed ${props.soundButton && 'on'}`} onClick={() => props.soundButton && props.setSoundButton(false)}>
								<img src={img_phone_red} />
							</div>
						</div>
					}

					{(sizeContainer.height > 0 &&LANGUAGE[props.lang].house.button[props.view]) &&
						<div className={'houseButtonContainer'}>
							<div onClick={() => set_modal(props.view)}>
								<span>{LANGUAGE[props.lang].house.button.title}</span>
								<span>{LANGUAGE[props.lang].house.button[props.view]}</span>
							</div>
						</div>
					}

					{(sizeContainer.height > 0 && props.view !== 'garage' && props.guided) &&
						<div className={'houseNextButtonContainer'}>
							<div onClick={() => nextRoom()}>
								<span>{LANGUAGE[props.lang].house.next}</span>
							</div>
						</div>
					}
				</div>
			{/* } */}

			{sizeContainer.height > 0 &&
				<div className={'housePhoneMobile'}>
					<div><img src={img_phone_mobile} alt={'phone'} /></div>
					<div className={`housePhoneGreen ${phone && 'on'}`} style={!props.soundButton ? {cursor: 'pointer'} : {}} onClick={onClickPhone}>
						<img src={img_phone_green} />
					</div>
					<div className={`housePhoneRed ${props.soundButton && 'on'}`} onClick={() => props.soundButton && props.setSoundButton(false)}>
						<img src={img_phone_red} />
					</div>
				</div>
			}

			<div id={'houseButtonContainerPhone'} className={'houseButtonContainerPhone'}>
				{(sizeContainer.height > 0 &&LANGUAGE[props.lang].house.button[props.view]) &&
					<div className={`houseButtonPhone ${(sizeContainer.height > 0 && props.view !== 'garage' && props.guided) && 'multi'}`} onClick={() => set_modal(props.view)}>
						<div>
							<span>{`${LANGUAGE[props.lang].house.button.title} ${isPhone ? LANGUAGE[props.lang].house.button[props.view].toUpperCase() : LANGUAGE[props.lang].house.button[props.view]}`}</span>
						</div>
					</div>
				}

				{(sizeContainer.height > 0 && props.view !== 'garage' && props.guided) &&
					<div className={`houseNextButtonPhone ${(sizeContainer.height > 0 &&LANGUAGE[props.lang].house.button[props.view]) && 'multi'}`} onClick={() => nextRoom()}>
						<div>
							<span>{LANGUAGE[props.lang].house.next}</span>
						</div>
					</div>
				}
			</div>

			{modal && renderModalItem()}
		</div>
	)
}

export default House