import	React, {useEffect, useState}			from 'react'
import	{Link}						from "react-router-dom"

import	img_menu					from '../ressources/images/Elements/menu_button.png'
import	img_close					from '../ressources/images/Elements/close_button.png'
import	img_close_mobile			from '../ressources/images/Elements/btn-close.png'
import	img_logo					from '../ressources/images/Elements/logo.png'
import	img_logo_fr					from '../ressources/images/Elements/logo_fr.png'

import	LANGUAGE					from '../ressources/language'
import	'./style.css'


function	Header(props) {
	const	[menu, set_menu] = useState(false)

	// useEffect(() => {
	// 	const body = document.body
	// 	body.style.position = menu ? 'fixed' : ''
	// }, [menu])

	const	onClick = newView => {
		set_menu(false)
		props.history.push(newView)
	}

	const	onClickLang = lang => {
		// props.setLang(lang)
		if (props.lang === lang)
			return

		if (lang === 'fr')
			window.location.href = `https://www.lamaisondelacromegalie.ca/${props.view}`
		if (lang === 'en')
			window.location.href = `https://www.acrhomegaly.ca/${props.view}`
	}

	function renderMenu() {

		return (
			<div className={'menu'}>
				<div className={'menuIcon'}>
					<img src={img_close} onClick={() => set_menu(!menu)} />
				</div>

				<span className={`menuItem ${props.view === 'livingroom' && 'active'}`} onClick={() => onClick('livingroom')}>{LANGUAGE[props.lang].menu.livingroom}</span>
				<span className={`menuItem ${props.view === 'kitchen' && 'active'}`} onClick={() => onClick('kitchen')}>{LANGUAGE[props.lang].menu.kitchen}</span>
				<span className={`menuItem ${props.view === 'bedroom' && 'active'}`} onClick={() => onClick('bedroom')}>{LANGUAGE[props.lang].menu.bedroom}</span>
				<span className={`menuItem ${props.view === 'bathroom' && 'active'}`} onClick={() => onClick('bathroom')}>{LANGUAGE[props.lang].menu.bathroom}</span>
				<span className={`menuItem ${props.view === 'garage' && 'active'}`} onClick={() => onClick('garage')}>{LANGUAGE[props.lang].menu.garage}</span>

				<div className={'menuLine'} />

				<span className={`menuItem ${props.view === 'glossary' && 'active'}`} onClick={() => onClick('glossary')}>{LANGUAGE[props.lang].menu.glossary}</span>
				<span className={`menuItem ${props.view === 'contact' && 'active'}`} onClick={() => onClick('contact')}>{LANGUAGE[props.lang].menu.contact}</span>
				<span className={`menuItem ${props.view === 'reference' && 'active'}`} onClick={() => onClick('reference')}>{LANGUAGE[props.lang].menu.reference}</span>

				<div className={'menuLang'}>
					<span className={props.lang === 'en' && 'menuLangSelect'} onClick={() => onClickLang('en')}>{'EN'}</span>
					<div />
					<span className={props.lang === 'fr' && 'menuLangSelect'} onClick={() => onClickLang('fr')}>{'FR'}</span>
				</div>

				<div className={'menuIconMobile'}>
					<img src={img_close_mobile} onClick={() => set_menu(!menu)} />
				</div>
			</div>
		)
	}

	return (
		[
			<div id={'header'} className={'headerMain'}>
				<div className={'headerMenuIcon'}>
					<img src={img_menu} onClick={() => set_menu(!menu)} />
				</div>

				<div className={'headerLogo'}>
					<img src={props.lang === 'fr' ? img_logo_fr : img_logo} onClick={() => onClick('home')} />
				</div>

				<div className={'headerLang'}>
					<span className={props.lang === 'en' && 'headerLangSelect'} onClick={() => onClickLang('en')}>{'EN'}</span>
					<div />
					<span className={props.lang === 'fr' && 'headerLangSelect'} onClick={() => onClickLang('fr')}>{'FR'}</span>
				</div>

			</div>,
			menu && renderMenu()
		]
	)
}

export default Header