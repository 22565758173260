import	React, {useState, useEffect}	from 'react'

import	LANGUAGE						from '../ressources/language'

import	'./style.css'

function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined
	})
	
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		window.addEventListener("resize", handleResize)
		handleResize()
		
		return () => window.removeEventListener("resize", handleResize)
	}, [])
	
	return windowSize
}

function	Glossary(props) {
	const	size = useWindowSize()

	return (
		<div className={'glossaryMain'} style={document.getElementById('header') ? {height: size.height - document.getElementById('header').offsetHeight} : null}>
			<div className={'glossaryTitle'}>{LANGUAGE[props.lang].reference.title}</div>

			<div className={'referenceContainer'}>
				<div>
					{LANGUAGE[props.lang].reference.text}
				</div>
			</div>
		</div>
	)
}

export default Glossary