import	React			from 'react'

const	text = {
	menu: {
		livingroom:	'Salon',
		kitchen:	'Cuisine',
		bedroom:	'Chambre',
		bathroom:	'Salle de bain',
		garage:		'Garage',
		glossary:	'Glossaire',
		contact:	'Contact',
		reference:	'Références'
	},

	home: {
		title:		'APPRENDRE À VIVRE AVEC L\'ACROMÉGALIE',
		select:		'Sélectionnez une pièce à explorer',
		or:			'OU',
		click:		'Cliquez sur le bouton pour suivre l\'expérience guidée',
		button:		'EXPÉRIENCE GUIDÉE'
	},

	house: {
		item: {
			livingroom_remote:	(
				<span>
					{'L\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' est due à une '}<b>{'sécrétion excessive d\''}<a href={'/glossary'}><u>{'hormone de croissance'}</u></a></b>{' (également appelée '}<a href={'/glossary'}><u>{'GH'}</u></a>{'). Le plus souvent, la production excessive de '}<a href={'/glossary'}><u>{'GH'}</u></a>{' est due à la présence d\'une '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{' située à l\'intérieur ou à proximité de l\'hypophyse. L\'hypophyse est rattachée à l\'hypothalamus situé juste sous la base du cerveau.'}<br /><br />
					{'La '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{' peut exercer une pression sur le cerveau, en particulier sur les nerfs optiques (qui transmettent les images de l\'œil au cerveau), entraînant '}<b>{'une vision floue et et un rétrécissement du champ de vision.'}</b>
					<br /><br />
					{'Ces symptômes touchent environ '}<b>{'entre 9% et 32% des personnes atteintes d\'une '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{' au niveau de l\'hypophyse.'}<sup>{'1'}</sup></b><br /><br />
					{'Les problèmes de vision cessent de s\'aggraver lorsque la '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{' est retirée et qu\'elle ne comprime plus le nerf optique.'}<br />
					{'Cependant, lorsque les dommages sont trop importants, les troubles de la vision peuvent être permanents.'}
				</span>
			),
			livingroom_book:	(
				<span>
					{'Le traitement de l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' a deux objectifs : arrêter la progression de la  '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{' et réduire les symptômes, et normaliser le taux d\'hormone de croissance afin de '}<b>{'contrôler l\'évolution de la maladie.'}</b><br />
					{'Il existe trois options de traitement:'}<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							<b>{'Le traitement chirurgical'}</b>{' consiste à enlever la '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{'. Cette '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{' est appelée adénome hypophysaire. La chirurgie est le traitement le plus courant. S\'il n\'y a pas d\'amélioration ou si la '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{' récidive, un traitement par médicaments et/ou radiothérapie est proposé.'}
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							<b>{'Le traitement médicamenteux'}</b>{' a pour but de réduire et de normaliser le taux d\''}<a href={'/glossary'}><u>{'hormone de croissance'}</u></a>{' ou d\'inhiber les effets de l\''}<a href={'/glossary'}><u>{'hormone de croissance'}</u></a>{'. Certains traitements peuvent également entraîner une réduction de la taille de la '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{'. Les médicaments sont utilisés en association avec la chirurgie et/ou la radiothérapie, notamment lorsque l\'ablation de la '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{' est incomplète. Ces médicaments peuvent avoir des effets indésirables, dont la fréquence et la gravité varient selon les produits et la durée d\'utilisation.'}
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							<b>{'La radiothérapie'}</b>{' consiste à utiliser des rayonnements (rayons X, rayons Gamma, etc.) pour détruire les cellules tumorales. La radiothérapie est très efficace pour éliminer les résidus de '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{' qui n\'ont pu être complètement retirés par la chirurgie et l\'empêcher de se développer à nouveau.'}<br />
							{'Il existe deux types de radiations disponibles au Canada pour le traitement de l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{'. '}<b>{'La radiothérapie stéréotaxique fractionnée'}</b>{', effectuée quotidiennement sur une période de 5 à 6 semaines avec de plus petites doses de rayonnement, ou '}<b>{'le Gamma Knife'}</b>{', effectué une seule fois à une très forte dose de rayonnement.'}<br /><br />
							{'Cependant, les effets de la radiothérapie sont lents et la régulation hormonale peut prendre jusqu\'à 5-10 ans. De plus, ce traitement peut entraîner des perturbations hormonales. Cette technique n\'est donc proposée qu\'en complément d\'un traitement chirurgical et/ou médicamenteux (sauf dans certains cas).'}
						</div>
					</div>
					<br /><br />
				</span>
			),
			livingroom_brochure: {
				download:		'Download pdf',
				acrhomegaly:	'Acromégalie Canada',
				mission:		'Our mission: to raise awareness of acromegaly and gigantism through education while providing a network of support for patients and their families across Canada.',
				contact:		(
					<span>
						<a href={'mailto:contact@acromegalycanada.ca'}>{'contact@acromegalycanada.ca'}</a><br /><br />
						<a href={'https://acromegalycanada.ca/'} target={'_blank'} rel={'noreferrer'}>{'https://acromegalycanada.ca/'}</a>
					</span>
				),
				sign:			'SIGNS AND SYMPTOMS',
				text1: (
					<span>
						{'Acromegaly can cause the following symptoms whther due to the tumour itself. If you are experiencing two or three of these, please reach out to your doctor and ask about GH or IGF-1 bloodwork or an oral glucose tolerance test.'}<br /><br />
						<div className={'listText'}>
							<div />
							<div>
								{'Headaches/migraines'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Profuse sweating'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Snoring/sleep apnea'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Joint pain'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Carpal tunnel syndrome'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Fatigue'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Breast leakage (when not pregnant; can also happen to males'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Infertility, amenorrhea, impotence, decreased sex drive'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Growth in hands and feet (increased ring, shoem and hat size)'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Swelling and growth of facual features, especially nose, chin, and brow'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Thicker hair and faster growing hair and nails'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Waight gain'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Weakness'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Skin tags'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Increased spacing of the teeth, overbite, underbite'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Difficulty concentrating'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Difficulty recalling words'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Difficulty speaking due to tongue swelling'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Difficulty controlling emotions and increase in angry reactions'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Loss of sight or double vision, blurriness'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Colon polyps'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Diabetes'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'High blood pressure'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Stroke'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Enlargement of the internal organs, potentially leading to heart issues'}
							</div>
						</div>
					</span>
				),
				what:			'WHAT ARE ACROMEGALY AND GIGANTISM?',
				text2: (
					<span>
						{'Acromegaly is a rare medical condition that is generally caused by a benign (non-cancerous) tumour that is secreting growth hormone (GH), The tumour is typically located inside or attached to the pituitary gland, but there have been some very rare cases where the tumour is located on an organ'}<br /><br />
						{'The pituitary gland is considered the master gland-it controls and regulates all our other glands and organs. It is a pea-sized gland located in the frontal part of our brain, about an inch back from the centre of our eyes'}<br /><br />
						{'Acromegaly is a condition that happens after puberty ends, but this condition can also happen in children and teens-it is then called gigantism.'}<br /><br />
						{'To confirm a diagnosis, a medical professional may suggest magnetic resonance imaging (MRI) and/ or an oral glucose tolerance test (OGTT). The first determines whether there is a pituitary tumour present and the second measures how quickly glucose is cleared from the blood. To help with the diagnosis, a doctor will request bloodwork for various hormones, including the growth hormone and insulin-like growth factor 1 (IGF-1).'}<br /><br />
						{'If a tumour is visible on the MRI, the first line of treatment is usually surgery to remove or reduce the tumour. This surgery is generally transsphenoidal: through the nasal cavities or less often, through the gums.'}<br /><br />
						{'If there is no visible tumour or the surgery does not reduce IGF-1 levels, there are several drugs that can help suppress IGF-1 and improve symptoms'}<br /><br />
						{'If surgery and drug therapy are not effective, radiation may be considered. This may be one dose or a series of doses. This decision will be made between you and your medical team and will depend on the size and location of the tumour'}<br /><br />
					</span>
				),
			},
			livingroom_rubikcube:	(
				<span>
					{'Une personne atteinte d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peut avoir des difficultés à se '}<b>{'concentrer'}</b>{' sur des tâches complexes.'}
					<br /><br />
					{'Ce symptôme peut être lié à:'}
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'la présence de la tumeur comprimant le cerveau,'}<br />
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'le rétablissement du cerveau suite à la mise en place d\'un traitement,'}<br />
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'la présence d\'autres symptômes comme des maux de tête ou de la fatigue.'}
						</div>
					</div>
				</span>
			),
			livingroom_waterBottle: (
				<span>
					{'Les '}<b>{'douleurs lombaires'}</b>{' sont un problème courant chez les personnes atteintes d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{'. Que la douleur soit chronique ou aiguë, il est important de consulter un professionnel de la santé qui pourra déterminer si la source de la douleur est inflammatoire ou mécanique. Il arrive qu’un type de douleur en entraîne un autre. En effet, les sources de douleur mécaniques entraînent souvent une inflammation des tissus mous.'}
					<br /><br />
					{'En fonction de l\'origine de la douleur, les personnes souffrant d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peuvent atténuer leurs douleurs lombaires en travaillant sur la '}<b>{'posture'}</b>{', en '}<b>{'s’étirant'}</b>{' et en '}<b>{'appliquant du chaud'}</b>{' ou du '}<b>{'froid'}</b>{' sur la zone endolorie.'}
					<br /><br />
					{'Une bonne posture peut prévenir et soulager les tensions dorsales, et améliorer les douleurs dorsales chroniques existantes. Travailler avec un physiatre, un docteur en médecine ostéopathique ou un bon chiropraticien peut aider à rééduquer le corps et à améliorer la posture.'}
					<br /><br />
					{'Les étirements peuvent libérer des '}<a href={'/glossary'}><u>{'endorphines'}</u></a>{' qui contribuent à réduire la douleur et à améliorer l’humeur.'}
					<br /><br />
					{'Enfin, la thérapie par le froid ou la chaleur peut soulager la douleur en utilisant '}<b>{'un coussin froid ou chaud'}</b>{' et un '}<b>{'bain chaud'}</b>{'. La thérapie par la chaleur doit être utilisée pour soulager la douleur uniquement lorsque la zone de la blessure n\'est plus dans un état inflammatoire.'}
				</span>
			),
			kitchen_water: (
				<span>
					{'Chez les personnes atteintes d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{', l’excès d\''}<a href={'/glossary'}><u>{'hormone de croissance'}</u></a>{' peut entrainer '}<b>{'l’augmentation du taux de sucre et de graisse dans le sang'}</b>{'. Cela peut provoquer un '}<a href={'/glossary'}><u>{'diabète de type 2'}</u></a>{', pouvant se manifester par de la '}<b>{'fatigue, une soif intense, la faim et de la '}<a href={'/glossary'}><u>{'polyurie'}</u></a></b>{' (augmentation du volume des urines).'}<br /><br />
					{'Le diabète nécessite une surveillance régulière pour éviter d’autres complications, notamment au niveau des reins et de la vision.'}<br /><br />
					{'Il est recommandé de suivre '}<b>{'une diète faible en gras, de surveiller sa glycémie régulièrement et de réaliser tous les 3 mois des tests sanguins '}<a href={'/glossary'}><u>{'A1C'}</u></a>{' chez son médecin.'}</b>
				</span>
			),
			kitchen_pillbox: (
				<span>
					{'Un taux d\''}<a href={'/glossary'}><u>{'hormone de croissance'}</u></a>{' trop élevé peut provoquer une '}<b><a href={'/glossary'}><u>{'hyperlipidémie secondaire'}</u></a></b>{' (augmentation du taux de graisse dans le sang).'}<br /><br />
					{'Cela peut provoquer une '}<b>{'hypertension artérielle'}</b>{', et entrainer des dommages au niveau des vaisseaux sanguins, du cœur et des reins.'}<br /><br />
					{'L\'hypertension artérielle touche environ '}<b>{'46% des personnes atteintes d’'}<a href={'/glossary'}><u>{'acromegalie'}</u></a>{'.'}<sup>{'2'}</sup></b>
				</span>
			),
			kitchen_pill: (
				<span>
					{'Chez les personnes atteintes d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{', la '}<a href={'/glossary'}><u>{'tumeur'}</u></a>{' hypophysaire cause différents symptômes.'}<br /><br />
					{'En grossissant, elle exerce une pression sur le cerveau ce qui peut provoquer '}<b>{'des maux de tête et des migraines.'}</b><br />
					{'Ce symptôme est très fréquent, il affecte environ '}<b>{'62% des personnes atteintes d’'}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{'.'}<sup>{'3'}</sup></b><br /><br />
					{'Ces maux de tête peuvent cesser avec un traitement.'}
				</span>
			),
			kitchen_knife: (
				<span>
					{'Le '}<b>{'syndrome du '}<a href={'/glossary'}><u>{'canal carpien'}</u></a></b>{' (ou '}<b>{'SCC'}</b>{') est une maladie fréquente chez les personnes souffrant d’'}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{'. Ce syndrome est causé par une '}<b>{'compression du nerf médian'}</b>{' situé dans l’avant-bras.'}<br /><br />

					{'Cette compression provoque différents symptômes : '}<b>{'engourdissement, picotements, faiblesse'}</b>{' et '}<b>{'douleur dans la main et le bras'}</b>{'.'}<br /><br />

					{'Dans la plupart des cas, les symptômes apparaissent progressivement, sans blessure spécifique. Au début, les symptômes peuvent aller et venir, puis ils peuvent s\'aggraver, se manifester plus fréquemment ou durer plus longtemps.'}<br /><br />

					{'Il existe '}<b>{'différentes options de traitement'}</b>{' du syndrome du canal carpien.'}
				</span>
			),
			kitchen_postIt: (
				<span>
					{'Des '}<b>{'troubles de la mémoire'}</b>{' modérés à sévères peuvent survenir chez les personnes vivant avec l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' et avoir un impact sur leur qualité de vie.'}<br /><br />

					{'Ces changements peuvent être associés à un '}<b>{'déficit de la mémoire, de l\'attention,'}</b>{' ainsi qu’à une '}<b>{'difficulté à se fixer des objectifs'}</b>{' et de les exécuter. Il arrive de ne pas être conscient de ces symptômes et de les associer au vieillissement. Les troubles de la mémoire, comme pour les symptômes courants, peuvent être évalués par un médecin.'}<br /><br />

					{'Recevoir un '}<b>{'soutien psychologique'}</b>{' peut améliorer la santé mentale et avoir un impact positif sur la qualité de vie.'}
				</span>
			),
			bedroom_mirror: (
				<span>
					{'L\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' est une maladie causée par une sécrétion excessive d\''}<a href={'/glossary'}><u>{'hormone de croissance'}</u></a>{', et se caractérise par une '}<b>{'croissance du visage.'}</b><br /><br />

					{'Cette croissance est plus ou moins importante selon si la maladie survient avant ou après la puberté.'}<br />
					{'Avant la puberté, on parle alors de '}<a href={'/glossary'}><u>{'gigantisme'}</u></a>{'.'}<br /><br />

					{'Chez l\'adulte, l\'augmentation du volume du visage est progressive, et la personne peut constater que ses chapeaux ou ses casques sont trop petits. Cela peut conduire au diagnostic de la maladie.'}<br /><br />

					{'Il est fréquent d\'observer un '}<b>{'grossissement des traits du visage'}</b>{' chez les personnes atteintes d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{'. Cela se traduit généralement par des rides au niveau du front, une proéminence des sourcils, un élargissement du nez et des oreilles, un épaississement des lèvres, des rides de la peau et des plis du nez, ainsi qu\'un prognathisme de la mâchoire inférieure, supérieure ou les deux, ce qui peut entraîner un espacement dentaire.'}<br /><br />

					{'Le plus souvent, '}<b>{'un traitement permettra de limiter l\'évolution des symptômes.'}</b><br /><br />

					{'Les changements physiques peuvent entraîner une diminution de l\'estime de soi.'}<br />
					{'Un professionnel de la santé mentale peut être une ressource pour aider à l\'acceptation des changements.'}

				</span>
			),
			bedroom_pillow: (
				<span>
					{'Une '}<b>{' transpiration excessive'}</b>{' peut également être un indice pour le diagnostic de l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{'.'}<br /><br />
					{'Dans certains cas, le traitement de l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peut aider à réguler la transpiration.'}<br /><br />
					{'Voici quelques conseils pour mieux gérer les sueurs nocturnes :'}<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Choisir des draps de lit plus légers.'}
						</div>
					</div>
					<div className={'listText'}>
						<div />
						<div>
							{'Baisser la température de la pièce avant le coucher.'}
						</div>
					</div>
					<div className={'listText'}>
						<div />
						<div>
							{'Utilisez un ventilateur.'}
						</div>
					</div>
					<div className={'listText'}>
						<div />
						<div>
							{'Prendre une douche tiède avant de dormir pour aider le corps à réguler sa température.'}
						</div>
					</div><br />
					{'Trouver la bonne solution pour rester au frais la nuit vous aidera à passer de meilleures nuits de sommeil.'}
				</span>
			),
			bedroom_rings: (
				<span>
					{'Une personne atteinte d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peut remarquer une '}<b>{'augmentation du volume des mains et des doigts.'}</b><br /><br />
					{'Par exemple, il devient difficile d\'enlever des bagues.'}<br />
					{'Cela '}<b>{'peut conduire au diagnostic'}</b>{' de la maladie.'}
				</span>
			),
			bedroom_bed: (
				<span>
					{'Dans la majorité des cas, l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' est due à une tumeur située à l\'intérieur ou à proximité de l\''}<a href={'/glossary'}><u>{'hypophyse'}</u></a>{'.'}<br />
					{'Cette glande sécrète de nombreuses hormones différentes, dont certaines ont '}<b>{'un effet direct sur les organes sexuels'}</b>{'.'}<br /><br />

					{'Cela peut expliquer que certaines personnes atteintes d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' puissent ressentir une '}<b>{'baisse de la libido'}</b>{', et des '}<b>{'dysfonctionnements érectiles'}</b>{' chez les hommes.'}<br /><br />

					{'Actuellement, il n\'est pas certain que les troubles de l\'érection et la baisse de la libido soient une conséquence directe et avérée de l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{'.'}
				</span>
			),
			bedroom_boxPaper: (
				<span>
					{'De nombreuses personnes vivant avec l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' ressentent des '}<b>{'changements d\'humeur'}</b>{' et '}<b>{'de comportement'}</b>{' qui impactent leur quotidien et provoquent un sentiment de détresse.'}<br /><br />

					<b>{'Les symptômes dépressifs'}</b>{' et '}<b>{'l\'anxiété'}</b>{' impactent considérablement la qualité de vie. Ces symptômes peuvent être '}<b>{'soignés'}</b>{' avec un soutien approprié, mais sont souvent sous-diagnostiqués.'}<br /><br />

					{'Le témoignage de personnes vivant avec l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' indique que les problèmes de santé mentale étaient les facteurs affectant le plus leur '}<b>{'qualité de vie.'}</b><sup>{'5'}</sup>{' C\'est pourquoi il est important de mettre davantage l\'accent sur le rôle de la '}<b>{'santé mentale'}</b>{' lors des discussions sur le traitement de l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{'.'}
				</span>
			),
			bathroom_toothbrush: (
				<span>
					{'L\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' provoque différentes altérations au niveau de la bouche. '}<br />
					{'La '}<b>{'croissance de la mâchoire'}</b>{' continue lentement et progressivement. La mâchoire inférieure peut s’avancer provoquant ce que l’on appelle un '}<a href={'/glossary'}><u>{'prognathisme'}</u></a>{'.'}<br /><br />
					{'Les '}<b>{'lèvres et la langue peuvent devenir plus épaisses'}</b>{', et le palais mou. Tous ces changements provoquent des '}<b>{'espacements entre les dents'}</b>{', un déchaussement des dents et le recul de la ligne des gencives.'}<br /><br />
					{'Ces symptômes peuvent mener au diagnostic de la maladie. '}
				</span>
			),
			bathroom_hairbrush: (
				<span>
					{'Un niveau trop élevé d\''}<a href={'/glossary'}><u>{'hormone de croissance'}</u></a>{' entraîne une '}<b>{'augmentation de la pilosité'}</b>{' (moustache, barbe, cheveux et poils).'}<br /><br />
					{'Cependant, '}<b>{'les traitements de l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' (médicaments et radiothérapie) peuvent entraîner une perte des cheveux'}</b>{' et une diminution générale de la croissance des cheveux.'}<br /><br />
					{'Les patients peuvent connaître une perte de cheveux plus importante après une chirurgie.'}
				</span>
			),
			bathroom_weightScale: (
				<span>
					{'Comme plusieurs parties du corps continuent de grandir, il est très fréquent chez une personne atteinte d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' de '}<b>{'prendre du poids'}</b>{'.'}<br /><br />
					{'Le manque d\'exercice physique dû à la fatigue et aux problèmes articulaires peut également entraîner une prise de poids.'}
				</span>
			),
			bathroom_toiletBag: (
				<span>
					{'L’'}<b>{'irrégularité menstruelle'}</b>{' est un symptôme fréquent chez les femmes atteintes d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{', qui se manifeste dans '}<b>{'40 à 84 % des cas'}</b>{'.'}<sup>{'6'}</sup><br /><br />

					{'Un taux élevé d\'hormone de croissance et un faible taux d\''}<b><a href={'/glossary'}><u>{'oestrogène'}</u></a></b>{' peuvent provoquer ce symptôme. Dans certains cas, cela peut conduire à une '}<b>{'absence de menstruation'}</b>{'.'}
				</span>
			),
			bathroom_facialCream: (
				<span>
					{'Les personnes vivant avec l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peuvent rencontrer des '}<b>{'problèmes de peau'}</b>{' qui peuvent être une source de détresse et d\'inquiétude majeure, tels que :'}
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'des '}<b>{'tâches cutanées pigmentées'}</b>{' sur le tronc, touchant 45% des personnes atteintes de la maladie'}<sup>{'7'}</sup>{','}<br />
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'une '}<b>{'peau grasse avec de larges pores'}</b>{' due à une sur-sécrétion des glandes sébacées est également majeure chez la plupart des personnes,'}<br />
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'l\''}<b>{'épaississement de la peau'}</b>{' et les '}<b>{'gonflements'}</b>{', principalement observés sur le visage, les mains et les pieds.'}
						</div>
					</div>
					<br /><br />
					{'Tous ces symptômes sont causés par des niveaux élevés d\''}<a href={'/glossary'}><u>{'hormone de croissance'}</u></a>{' (GH) et de '}<a href={'/glossary'}><u>{'facteur de croissance insulinique 1'}</u></a>{' (IGF-1). Le contrôle de la sur-sécrétion de ces hormones guérit la plupart des problèmes cutanés de l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' et l\'apparence de la peau peut rapidement s’améliorer. Cependant, si la progression de la maladie n\'est pas arrêtée, certaines manifestations dermatologiques peuvent continuer à progresser ou à régresser.'}<br /><br />

					{'Il est important de '}<b>{'faire part à son médecin'}</b>{' de tous '}<b>{'ses changements cutanés'}</b>{'. Le fait de porter ces symptômes à l\'attention d’un médecin peut contribuer à un diagnostic précoce et donc à la mise en place d\'un traitement.'}
				</span>
			),
			garage_bicycle: (
				<span>
					{'La production excessive d\’'}<a href={'/glossary'}><u>{'hormone de croissance '}</u></a>{' peut provoquer des '}<b>{'douleurs au dos, diverses douleurs articulaires'}</b>{'  (ce symptôme touche 2/3 des personnes atteintes d’acromégalie), '}<b>{'des malformations osseuses ainsi qu’une fatigue chronique importante.'}</b><br /><br />

					{'C’est pourquoi, il peut être difficile de continuer à pratiquer une activité sportive régulière.'}<br /><br />

					{'La motivation peut aussi parfois manquer face à tous les changements auxquels une personne atteinte d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' est confrontée.'}<br /><br />

					{'Des sports peu contraignants tels que '}<b>{'la natation, la marche ou le vélo'}</b>{' peuvent être priorisés.'}
				</span>
			),
			garage_shoes: (
				<span>
					{'La production excessive d\''}<a href={'/glossary'}><u>{'hormone de croissance'}</u></a>{' entraîne à la fois '}<b>{'le gonflement des tissus mous et la croissance osseuse.'}</b><br /><br />
					{'Ces symptômes sont souvent des '}<b>{'signes d\'alerte'}</b>{' au moment du diagnostic de la maladie.'}
				</span>
			),
			garage_microphone: (
				<span>
					{'Les personnes atteintes d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peuvent également connaître des '}<b>{'changements dans leur voix.'}</b><br />
					{'Elle peut sembler plus grave et plus rauque.'}<br /><br />

					{'Ceci est dû à l\''}<b>{'épaississement du '}<a href={'/glossary'}><u>{'larynx'}</u></a></b>{' (un des conduits du système respiratoire) qui contient les cordes vocales.'}
				</span>
			),
			garage_weigh: (
				<span>
					{'La '}<b>{'faiblesse et la douleur musculaire'}</b>{' sont récurrentes chez les personnes vivant avec l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{', et impactent leur bien-être.'}<br /><br />

					{'Les deux hormones '}<a href={'/glossary'}><u>{'GH/IGF-1'}</u></a>{' agissent dans le muscle, modifiant sa fonction et sa structure. Ceci explique pourquoi la perte de force a été décrite chez les personnes vivant avec une '}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' active. Une longue durée de la maladie peut également avoir un impact sur la force musculaire et l\'équilibre.'}<br /><br />

					{'La relation entre la taille et la fonction des muscles est non proportionnelle. En effet, les personnes souffrant d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peuvent observer une augmentation de la force bien après que leur muscle ait grossi.'}<sup>{'8'}</sup>{' Ainsi, la '}<b>{'force d\'extension et de flexion du genou'}</b>{' devrait s\'améliorer après 2 ans de traitement. Mais il suffit de quelques mois de traitement pour que le '}<b>{'volume musculaire'}</b>{' augmente. '}<b>{'La force de préhension des mains'}</b>{' peut également s\'améliorer après le traitement.'}<br /><br />

					{'Les '}<b>{'programmes de rééducation à domicile'}</b>{' peuvent améliorer la fonction musculaire, la fatigue générale et l\'équilibre corporel. Ces programmes proposent des pratiques complémentaires au traitement qui peuvent soulager la douleur et améliorer la force musculaire.'}
				</span>
			),
			garage_knee: (
				<span>
					{'Les deux hormones à l\'origine de l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' provoquent également des '}<b><a href={'/glossary'}><u>{'anomalies articulaires'}</u></a></b>{', limitant l\'amplitude des mouvements. Si le taux de '}<a href={'/glossary'}><u>{'GH'}</u></a>{' n\'est pas régulé suffisamment tôt (dû à un diagnostic tardif par exemple) l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peut avoir des effets similaires à ceux d\'une maladie dégénérative des articulations. Dans cette phase tardive, les dommages peuvent devenir irréversibles. Dans ce cas, le traitement de l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' ne peut avoir qu\'un impact limité sur les symptômes articulaires.'}<br /><br />

					{'Lorsque les traitements non chirurgicaux ne permettent plus de soulager la douleur, des '}<b>{'opérations de remplacement des articulations de l\'épaule, de la hanche'}</b>{' et '}<b>{'du genou'}</b>{' peuvent être nécessaires. Dans ce cas, les parties endommagées des articulations seront remplacées par des composants artificiels, appelés '}<b><a href={'/glossary'}><u>{'prothèses'}</u></a></b>{'. Un médecin de famille peut conseiller un orthopédiste qui pourra déterminer, après une évaluation approfondie, si une intervention chirurgicale est nécessaire ou non pour '}<b>{'soulager la douleur'}</b>{' et '}<b>{'rétablir l\'amplitude des mouvements'}</b>{'.'}<br /><br />

					{'Après une chirurgie de remplacement d\'une articulation, les personnes concernées peuvent porter une '}<b>{'attelle'}</b>{' pendant quelques semaines pour soutenir et protéger l\'épaule ou le genou.'}
				</span>
			)
		},
		animated: {
			kitchen: (
				<span>
					<b>{'Explorez les changements du corps en lien avec le diabète :'}</b><br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'L\''}<a href={'/glossary'}><u>{'hormone de croissance'}</u></a>{' induit la libération de sucre et de graisse dans les vaisseaux sanguins.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'L\'insuline, une hormone qui régule le taux de sucre dans le sang, devient inefficace pour combattre le diabète induit par l\''}<a href={'/glossary'}><u>{'hormone de croissance'}</u></a>{'.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Les personnes atteintes d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' ont tendance à développer un '}<a href={'/glossary'}><u>{'diabète de type 2'}</u></a>{' à un plus jeune âge que la population générale.'}
						</div>
					</div>
				</span>
			),
			kitchen_harm: (
				<span>
					<div className={'listText'}>
						<div />
						<div>
							{'L\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peut entraîner un taux élevé de graisse dans le sang.'}
						</div>
					</div><br />
					<div className={'listText'}>
						<div />
						<div>
							{'Si cette situation n\'est pas prise en charge, elle peut entraîner l\'apparition de dépôts dans les artères, ce qui peut les boucher.'}<br />
							{'Ce phénomène est appelé athérosclérose.'}
						</div>
					</div><br />
					<div className={'listText'}>
						<div />
						<div>
							{'Ce symptôme touche entre 19% et 56% des personnes atteintes d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{'.'}<sup>{'4'}</sup>
						</div>
					</div>
				</span>
			),
			kitchen_heart: (
				<span>
					<div className={'listText'}>
						<div />
						<div>
							{'Les personnes atteintes d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' et de diabète ont plus de risques de développer une hypertension artérielle.'}
						</div>
					</div><br />
					<div className={'listText'}>
						<div />
						<div>
							{'Le diabète peut entraîner une aggravation de la '}<a href={'/glossary'}><u>{'cardiomyopathie'}</u></a>{'.'}
						</div>
					</div>
				</span>
			),
			bedroom: (
				<span>
					{'L\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peut entraîner plusieurs altérations au niveau de la bouche :'}<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'Augmentation et épaississement de la luette.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Une lèvre inférieure épaissie et '}<a href={'/glossary'}><u>{'éversée'}</u></a>{'.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Un élargissement de la langue ('}<a href={'/glossary'}><u>{'macroglossie'}</u></a>{').'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Mâchoire inférieure avancée ('}<a href={'/glossary'}><u>{'prognathisme'}</u></a>{').'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Voies respiratoires obstruées.'}
						</div>
					</div>
					<br />
				</span>
			),
			bathroom: (
				<span>
					{'L\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peut entraîner différents symptômes affectant les articulations :'}<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'Épaississement du cartilage.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Élargissement des articulations.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Hypomobilité des articulations.'}
						</div>
					</div>
					<br /><br />

					{'Les symptômes ostéo-articulaires de l\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' sont très fréquents et peuvent être les premiers à se manifester chez une grande partie des personnes atteintes d\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{'.'}<br /><br />

					{'L\'apparition d\''}<b><a href={'/glossary'}><u>{'arthrose'}</u></a></b>{', la forme la plus courante d\''}<b><a href={'/glossary'}><u>{'arthrite'}</u></a></b>{', est fréquente chez les personnes atteintes d’'}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{'. Elle se manifeste le plus souvent dans '}<b>{'les mains, les hanches, les épaules et les genoux'}</b>{'.'}<br /><br />

					{'Elle se développe généralement lentement et peut s\'aggraver avec le temps. L\''}<a href={'/glossary'}><u>{'arthrose'}</u></a>{' peut provoquer des douleurs, des raideurs et des gonflements. Dans certains cas, elle peut aussi entraîner une réduction des activités et un handicap.'}<br /><br />

					{'Un diagnostic précoce de l\''}<a href={'/glossary'}><u>{'arthropathie'}</u></a>{' et la mise en place d\'un traitement adapté peut permettre de réduire les complications articulaires graves.'}
				</span>
			),
			garage: (
				<span>
					{'L\''}<a href={'/glossary'}><u>{'acromégalie'}</u></a>{' peut affecter le cœur :'}<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'Augmentation de la taille du cœur.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Épaississement de la paroi cardiaque.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Troubles du rythme cardiaque.'}
						</div>
					</div>
				</span>
			)
		},
		button: {
			title:		'EN SAVOIR PLUS SUR',
			kitchen:	'le diabète',
			bedroom:	'l\'apnée du sommeil',
			bathroom:	'l\'arthropathie',
			garage:		'la cardiomyopathie',

			switch: {
				bathroom:	'Rayon X',
				garage:		'Vue interne',
				default:	'Vue anatomique'
			}
		},

		next:	'PIÈCE SUIVANTE',
		swipe:	'Glissez vers la gauche'
	},

	glossary: {
		title:		'GLOSSAIRE',
		text: (
			<span>
				<b>{'Acromégalie:'}</b>{' un déséquilibre hormonal souvent causé par une tumeur dans l\'hypophyse qui induit une croissance anormale des mains, des pieds et du visage parmi d\'autres symptômes.'}<br /><br />
				<b>{'Anomalie articulaire:'}</b>{' structure ou forme anormale des articulations.'}<br /><br />
				<b>{'Apnée du sommeil:'}</b>{' trouble du sommeil provoquant une apnée (arrêt du flux respiratoire/arrêt de la respiration) ou une diminution du flux respiratoire.'}<br /><br />
				<b>{'Arthrite:'}</b>{' Le terme arthrite est utilisé pour décrire plus de 100 troubles médicaux qui touchent les articulations, les tissus qui les entourent et d\'autres tissus conjonctifs.Habituellement, la plupart des formes d\'arthrite sont caractérisées par la douleur et la raideur dans une ou plusieurs articulations et autour de celles-ci. Toutefois, l\'évolution, la gravité et la localisation des symptômes varient selon la forme particulière de la maladie. Les symptômes peuvent se manifester progressivement ou soudainement.'}<br /><br />
				<b>{'Arthropathie:'}</b>{' maladie des articulations qui réduit l\'amplitude des mouvements et provoque l\'érosion osseuse ainsi que des douleurs articulaires.'}<br /><br />
				<b>{'Arthrose:'}</b>{' est une maladie évolutive des articulations entières qui touche le plus souvent les articulations des mains, des pieds, des hanches, des genoux et de la colonne vertébrale, bien que presque toutes les articulations puissent être touchées. L\'arthrose est la forme d\'arthrite la plus courante et une cause importante d\'invalidité dans le monde.'}<br /><br />
				<b>{'Canal carpien:'}</b>{' est un espace délimité par les os du poignet et un ligament rigide qui relie les os les uns aux autres.  C\'est par ce canal que passent le nerf médian de la main ainsi que les tendons des muscles fléchisseurs des doigts. Ces tendons relient les muscles aux os de la main; c\'est par eux que les mouvements des muscles sont relayés aux os. Le nerf médian transmet les signaux par lesquels le cerveau contrôle les mouvements des doigts et des mains. Le nerf médian achemine aussi, de la main au cerveau, de l\'information sur la température, la douleur et les sensations tactiles et contrôle la transpiration de la main.'}<br /><br />
				<b>{'Cardiomyopathie:'}</b>{' maladie du muscle cardiaque qui empêche le cœur de pomper correctement le sang vers le reste du corps.'}<br /><br />
				<b>{'Diabète de type 2:'}</b>{' maladie chronique caractérisée par une glycémie trop élevée due à une production insuffisante ou à une mauvaise action de l\'insuline.'}<br /><br />
				<b>{'Dysmorphie:'}</b>{' terme médical désignant une forme anormale d\'un organe ou d\'une partie du corps.'}<br /><br />
				<b>{'Endorphines:'}</b>{' hormones libérées par l\'hypothalamus et l\'hypophyse en réponse à la douleur ou au stress. Ce groupe d\'hormones soulage la douleur et crée un sentiment général de bien-être. Les endorphines sont les antidouleurs naturels du corps.'}<br /><br />
				<b>{'Œstrogène:'}</b>{' catégorie d\'hormone sexuelle intervenant dans le développement et la régulation des organes sexuels féminins et dans le développement des caractères sexuels secondaires chez la femme.'}<br /><br />
				<b>{'Éversion:'}</b>{' retournement vers l\'intérieur ou l\'extérieur.'}<br /><br />
				<b>{'Gigantisme :'}</b>{' croissance anormale due à un excès d\'hormone de croissance (GH) pendant l\'enfance.'}<br /><br />
				<b>{'Hormone de croissance'}</b>{' ou '}<b>{'GH'}</b>{' (growth hormone): hormone (HGH pour les humains, humain growth hormone) qui stimule la reproduction/régénération des cellules et la croissance. '}<br /><br />
				<b>{'Hyperlipidémie secondaire:'}</b>{' quantité anormalement élevée de lipides dans le sang induite par des facteurs non génétiques, comme l\'environnement.'}<br /><br />
				<b>{'Hypophyse:'}</b>{' glande endocrine située à la base du cerveau, derrière les sinus. Elle produit une grande variété d\'hormones comme la GH.'}<br /><br />
				<b>{'IGF-1 (Insulin Glucose Factor – 1):'}</b>{' hormone entraînant la croissance des os et ligaments.'}<br /><br />
				<b>{'Larynx:'}</b>{' Organe situé dans la gorge. Il assure plusieurs fonctions telles que la production du son (voix) et la séparation des aliments et de l\'air vers leurs organes respectifs.'}<br /><br />
				<b>{'Macroglossie:'}</b>{' élargissement anormal de la langue.'}<br /><br />
				<b>{'OGTT:'}</b>{' (Oral Glucose Tolerance Test) test oral permettant de déterminer la glycémie.'}<br /><br />
				<b>{'Polyurie:'}</b>{' production excessive d\'urine.'}<br /><br />
				<b>{'Prognathisme:'}</b>{' projection vers l\'avant de la mâchoire supérieure ou inférieure entraînant un mauvais alignement des dents et de la bouche.'}<br /><br />
				<b>{'Prothèse:'}</b>{' dispositif conçu pour remplacer une partie du corps manquante ou pour permettre à une partie du corps de mieux fonctionner.'}<br /><br />
				<b>{'Test A1C:'}</b>{' test sanguin mesurant le taux moyen de sucre dans le sang au cours des 3 derniers mois.'}<br /><br />
				<b>{'Tumeur:'}</b>{' groupe de cellules anormales, se développant de manière incontrôlée et créant une masse.'}<br /><br />
			</span>
		)
	},

	contact: {
		title:	'CONTACT',
		text: (
			<span>
				{'Acromégalie Canada a pour mission de sensibiliser la population grâce à l\'éducation, tout en offrant un réseau de soutien aux patients et à leurs familles dans tout le Canada.'}<br /><br />
				<a href={'mailto:contact@acromegalycanada.ca'}>{'contact@acromegalycanada.ca'}</a><br /><br />
				<a href={'https://acromegalycanada.ca/'} target={'_blank'} rel={'noreferrer'}>{'https://acromegalycanada.ca/'}</a><br /><br />
			</span>
		),
		thanks:	'Acromégalie Canada remercie ces organisations pour leur soutien au projet La maison de l\'acromégalie.'
	},

	reference: {
		title:	'RÉFÉRENCES',
		text: (
			<span>
				<div className={'listText ref ref1'}>
					<div>{'1.'}</div>
					<div>
						{'Anderson D, Faber P, Marcovitz S, Hardy J, Lorenzetti D. Pituitary tumors and the ophthalmologist. Ophthalmology. 1983 Nov;90(11):1265-70. doi: 10.1016/s0161-6420(83)34393-1. PMID: 6664664.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref2'}>
					<div>{'2.'}</div>
					<div>
						{'Vitale G, Pivonello R, Auriemma RS, et al. . Hypertension in acromegaly and in the normal population: prevalence and determinants. Clin Endocrinol (Oxf). 2005;63:470–476.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref3'}>
					<div>{'3.'}</div>
					<div>
						{'Geer, E.B., Sisco, J., Adelman, D.T. et al. Observed discordance between outcomes reported by acromegaly patients and their treating endocrinology medical provider. Pituitary 23, 140–148 (2020).'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref4'}>
					<div>{'4.'}</div>
					<div>
						{'Mazziotti, G. et al. Influence of diabetes mellitus on vertebral fractures in men with acromegaly. Endocrine. 2011 Aug.40(1):102-8.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref5'}>
					<div>{'5.'}</div>
					<div>
						{'Geraedts VJ, Dimopoulou C, Auer M, Schopohl J, Stalla GK, Sievers C. Health Outcomes in Acromegaly: Depression and Anxiety are Promising Targets for Improving Reduced Quality of Life. Front Endocrinol (Lausanne). 2015 Jan 6;5:229. doi: 10.3389/fendo.2014.00229. PMID: 25610427; PMCID: PMC4285111.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref6'}>
					<div>{'6.'}</div>
					<div>
						{'Kaltsas GA, Mukherjee JJ, Jenkins PJ, Satta MA, Islam N, Monson JP, Besser GM, Grossman AB. Menstrual irregularity in women with acromegaly. J Clin Endocrinol Metab. 1999 Aug;84(8):2731-5. doi: 10.1210/jcem.84.8.5858. PMID: 10443669.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref7'}>
					<div>{'7.'}</div>
					<div>
						{'Ben-Shlomo A, Melmed S. Skin manifestations in acromegaly. Clin Dermatol. 2006 Jul-Aug;24(4):256-9. doi: 10.1016/j.clindermatol.2006.04.011. PMID: 16828406.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref8'}>
					<div>{'8.'}</div>
					<div>
						{'Füchtbauer L, Olsson DS, Bengtsson BÅ, Norrman LL, Sunnerhagen KS, Johannsson G. Muscle strength in patients with acromegaly at diagnosis and during long-term follow-up. Eur J Endocrinol. 2017 Aug;177(2):217-226. doi: 10.1530/EJE-17-0120. Epub 2017 May 31. PMID: 28566445.'}
					</div>
				</div>
			</span>
		)
	}
}

export default text