import	React			from 'react'

const	text = {
	menu: {
		livingroom:	'Living room',
		kitchen:	'Kitchen',
		bedroom:	'Bedroom',
		bathroom:	'Bathroom',
		garage:		'Garage',
		glossary:	'Glossary',
		contact:	'Contact us',
		reference:	'References'
	},

	home: {
		title:		'LEARN TO LIVE WITH ACROMEGALY',
		select:		'Select a room to explore',
		or:			'OR',
		click:		'Click on the button to follow the guided experience',
		button:		'GUIDED EXPERIENCE'
	},

	house: {
		item: {
			livingroom_remote:	(
				<span>
					<a href={'/glossary'}><u>{'Acromegaly'}</u></a>{' is caused by '}<b>{'excessive secretion of '}<a href={'/glossary'}><u>{'growth hormone'}</u></a></b>{' (also called '}<a href={'/glossary'}><u>{'GH'}</u></a>{'). Most  often the excessive production of '}<a href={'/glossary'}><u>{'GH'}</u></a>{' is due  to the presence of a '}<a href={'/glossary'}><u>{'tumour'}</u></a>{' located in or  attached to the pituitary gland. The pituitary gland is attached to the hypothalamus located just under the base of the brain. '}<br /><br />
					{'The '}<a href={'/glossary'}><u>{'tumour'}</u></a>{' can put pressure on the brain,  particularly on the optic nerves (which  transmit images from the eye to the brain), causing '}<b>{'blurred vision and reduced  peripheral vision.'}</b>
					<br /><br />
					{'These symptoms affect about '}<b>{'9% to 32% of people living with a pituitary '}<a href={'/glossary'}><u>{'tumour'}</u></a>{'.'}<sup>{'1'}</sup></b><br /><br />
					{'The vision issues will stop getting worse once the '}<a href={'/glossary'}><u>{'tumour'}</u></a>{' has been removed and is no longer compressing the optic nerve.'}<br />
					{'However, when the damage is too severe, the vision loss can be permanent.'}
				</span>
			),
			livingroom_book:	(
				<span>
					{'Treatment for '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' has two objectives: to stop the progression of the '}<a href={'/glossary'}><u>{'tumour'}</u></a>{' and reduce the symptoms, and to normalize the level of '}<a href={'/glossary'}><u>{'growth hormone'}</u></a>{' in '}<b>{'order to control the evolution of the disease.'}</b><br />
					{'There are three treatment options:'}<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							<b>{'Surgical treatment'}</b>{' involves removing the '}<a href={'/glossary'}><u>{'tumour'}</u></a>{'. This '}<a href={'/glossary'}><u>{'tumour'}</u></a>{' is called a pituitary adenoma. Surgery is the most common treatment. If there’s no improvement or if the '}<a href={'/glossary'}><u>{'tumour'}</u></a>{' recurs, treatment with medication and/or radiotherapy is proposed.'}
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'The aim of '}<b>{'drug treatment'}</b>{' is to reduce and normalize the '}<a href={'/glossary'}><u>{'growth hormone'}</u></a>{' level or to inhibit effects of the '}<a href={'/glossary'}><u>{'growth hormone'}</u></a>{'. Some treatments can also lead to a reduction in '}<a href={'/glossary'}><u>{'tumour'}</u></a>{' size. Drugs are used in combination with surgery and/or radiotherapy, especially when the removal of the '}<a href={'/glossary'}><u>{'tumour'}</u></a>{' is incomplete. These drugs can have adverse effects, the frequency and severity of which differ between products and depending on the duration of use.'}
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							<b>{'Radiotherapy'}</b>{' is the use of radiation (X-rays, Gamma rays etc.) to destroy '}<a href={'/glossary'}><u>{'tumour'}</u></a>{' cells. Radiotherapy is very effective in removing '}<a href={'/glossary'}><u>{'tumour'}</u></a>{' residues that could not be completely removed by surgery and stop them from growing again.'}<br />
							{'There are 2 types of radiation available in Canada for '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' treatment. '}<b>{'The Fractionated Stereotactic radiotherapy'}</b>{', done daily over a course of 5-6 weeks with smaller dosages of radiation, or the '}<b>{'Gamma Knife'}</b>{', done once at a very high dosage of radiation.'}<br /><br />
							{'However, the effects of radiotherapy are slow and hormonal regulation can take up to 5  or 10 years. In addition, it can lead to hormonal disturbances. This technique is therefore only proposed as a complement to surgical treatment and/or medication (except in some cases).'}
						</div>
					</div>

					<br /><br />
				</span>
			),
			livingroom_brochure: {
				download:		'Download pdf',
				acrhomegaly:	'Acromégalie Canada',
				mission:		'Our mission: to raise awareness of acromegaly and gigantism through education while providing a network of support for patients and their families across Canada.',
				contact:		(
					<span>
						<a href={'mailto:contact@acromegalycanada.ca'}>{'contact@acromegalycanada.ca'}</a><br /><br />
						<a href={'https://acromegalycanada.ca/'} target={'_blank'} rel={'noreferrer'}>{'https://acromegalycanada.ca/'}</a>
					</span>
				),
				sign:			'SIGNS AND SYMPTOMS',
				text1: (
					<span>
						{'Acromegaly can cause the following symptoms whther due to the tumour itself. If you are experiencing two or three of these, please reach out to your doctor and ask about GH or IGF-1 bloodwork or an oral glucose tolerance test.'}<br /><br />
						<div className={'listText'}>
							<div />
							<div>
								{'Headaches/migraines'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Profuse sweating'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Snoring/sleep apnea'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Joint pain'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Carpal tunnel syndrome'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Fatigue'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Breast leakage (when not pregnant; can also happen to males'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Infertility, amenorrhea, impotence, decreased sex drive'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Growth in hands and feet (increased ring, shoem and hat size)'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Swelling and growth of facual features, especially nose, chin, and brow'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Thicker hair and faster growing hair and nails'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Waight gain'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Weakness'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Skin tags'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Increased spacing of the teeth, overbite, underbite'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Difficulty concentrating'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Difficulty recalling words'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Difficulty speaking due to tongue swelling'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Difficulty controlling emotions and increase in angry reactions'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Loss of sight or double vision, blurriness'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Colon polyps'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Diabetes'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'High blood pressure'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Stroke'}
							</div>
						</div>
						<div className={'listText'}>
							<div />
							<div>
								{'Enlargement of the internal organs, potentially leading to heart issues'}
							</div>
						</div>
					</span>
				),
				what:			'WHAT ARE ACROMEGALY AND GIGANTISM?',
				text2: (
					<span>
						{'Acromegaly is a rare medical condition that is generally caused by a benign (non-cancerous) tumour that is secreting growth hormone (GH), The tumour is typically located inside or attached to the pituitary gland, but there have been some very rare cases where the tumour is located on an organ'}<br /><br />
						{'The pituitary gland is considered the master gland-it controls and regulates all our other glands and organs. It is a pea-sized gland located in the frontal part of our brain, about an inch back from the centre of our eyes'}<br /><br />
						{'Acromegaly is a condition that happens after puberty ends, but this condition can also happen in children and teens-it is then called gigantism.'}<br /><br />
						{'To confirm a diagnosis, a medical professional may suggest magnetic resonance imaging (MRI) and/ or an oral glucose tolerance test (OGTT). The first determines whether there is a pituitary tumour present and the second measures how quickly glucose is cleared from the blood. To help with the diagnosis, a doctor will request bloodwork for various hormones, including the growth hormone and insulin-like growth factor 1 (IGF-1).'}<br /><br />
						{'If a tumour is visible on the MRI, the first line of treatment is usually surgery to remove or reduce the tumour. This surgery is generally transsphenoidal: through the nasal cavities or less often, through the gums.'}<br /><br />
						{'If there is no visible tumour or the surgery does not reduce IGF-1 levels, there are several drugs that can help suppress IGF-1 and improve symptoms'}<br /><br />
						{'If surgery and drug therapy are not effective, radiation may be considered. This may be one dose or a series of doses. This decision will be made between you and your medical team and will depend on the size and location of the tumour'}<br /><br />
					</span>
				),
			},
			livingroom_rubikcube:	(
				<span>
					{'People living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' may find it difficult to '}<b>{'concentrate'}</b>{' on complex tasks.'}
					<br /><br />
					{'This symptom may be directly related to:'}
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'the presence of the tumour compressing the brain,'}<br />
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'the brain recovering following the initiation of treatment,'}<br />
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'other symptoms such as headaches or fatigue.'}
						</div>
					</div>
				</span>
			),
			livingroom_waterBottle: (
				<span>
					<b>{'Lower back pain'}</b>{' is a common issue for people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'. Whether the pain is chronic or acute, it is important to talk with a healthcare provider who can identify if the source of the pain is inflammatory or mechanical. As one type of pain can lead to another, mechanical sources of pain often lead to soft tissue inflammation.'}
					<br /><br />
					{'Depending on the origin of the pain, people suffering from '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' can manage their lower back pain by taking actions such as working on their '}<b>{'posture, stretching'}</b>{', and applying '}<b>{'cold or heat therapy.'}</b>
					<br /><br />
					{'Good posture can prevent and relieve back tension, and improve existing chronic back pain. Working with a physiatrist, a doctor of osteopathic medicine or a good chiropractor can help retrain the body and improve posture.'}
					<br /><br />
					{'Stretching may release '}<a href={'/glossary'}><u>{'endorphins'}</u></a>{' that help to reduce pain and enhance your mood.'}
					<br /><br />
					{'Finally, cold or heat therapy can relieve pain by using a '}<b>{'cold or hot pad'}</b>{' and a '}<b>{'warm bath'}</b>{'. Heat therapy should only be used for pain relief when the site of injury is no longer in an inflammatory state.'}
				</span>
			),
			kitchen_water: (
				<span>
					{'In people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{', the excess of '}<a href={'/glossary'}><u>{'growth hormone'}</u></a>{' can lead to '}<b>{'increased blood sugar and fat levels'}</b>{'. This can lead to '}<a href={'/glossary'}><u>{'type-2 diabetes'}</u></a>{', which shows up in '}<b>{'fatigue, intense thirst, hunger, and '}<a href={'/glossary'}><u>{'polyuria'}</u></a></b>{' (increased urine volume).'}<br /><br />
					{'Diabetes requires regular monitoring to avoid further complications, especially concerning the kidneys and vision.'}<br /><br />
					{'It is recommended to '}<b>{'follow a fairly strict low-fat diet, monitor blood sugar levels regularly, and have regular 3-month check-ups with your doctor for '}<a href={'/glossary'}><u>{'A1C'}</u></a>{' level bloodwork.'}</b>
				</span>
			),
			kitchen_pillbox: (
				<span>
					{'Too much '}<a href={'/glossary'}><u>{'growth hormone'}</u></a>{' can cause '}<b><a href={'/glossary'}><u>{'secondary hyperlipidemia'}</u></a></b>{' (an increase of fat in the blood).'}<br /><br />
					{'This can lead to '}<b>{'high blood pressure'}</b>{', and damage to the blood vessels, heart and kidneys.'}<br /><br />
					{'High blood pressure affects about '}<b>{'46% of people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'.'}<sup>{'2'}</sup></b>
				</span>
			),
			kitchen_pill: (
				<span>
					{'In  people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{', the pituitary '}<a href={'/glossary'}><u>{'tumour'}</u></a>{' causes many different symptoms.'}<br /><br />
					{'As it grows, it puts pressure on the brain, which can cause '}<b>{'headaches and migraines.'}</b><br />
					{'This symptom is very common, affecting '}<b>{'62% of people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'.'}<sup>{'3'}</sup></b><br /><br />
					{'The headaches may stop with treatment.'}
				</span>
			),
			kitchen_knife: (
				<span>
					<b><a href={'/glossary'}><u>{'Carpal tunnel'}</u></a>{' syndrome'}</b>{' (or '}<b>{'CTS'}</b>{') is a common disease in people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'.'}<br />
					{'It is caused by the '}<b>{'compression of the median nerve'}</b>{' in the forearm.'}<br /><br />
					{'This compression can lead to different symptoms: '}<b>{'numbness, tingling, weakness,'}</b>{' and '}<b>{'pain in the hand'}</b>{' and '}<b>{'arm'}</b>{'.'}<br /><br />
					{'In most cases, symptoms appear progressively, without a particular injury. At first, symptoms may come and go. Then they may worsen, occur more regularly, or last for longer periods of time.'}<br /><br />
					{'There are '}<b>{'different treatment options'}</b>{' for carpal tunnel syndrome.'}
				</span>
			),
			kitchen_postIt: (
				<span>
					{'Moderate to severe '}<b>{'memory impairment'}</b>{' can occur in people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' and impact their quality of life.'}<br /><br />
					{'These changes can be associated with a '}<b>{'deficit of memory, attention,'}</b>{' as well as '}<b>{'goal setting,'}</b>{' and '}<b>{'execution'}</b>{'. People may not be aware of these symptoms and may correlate them with aging. Memory impairment, as for common symptoms, can be evaluated by a doctor.'}<br /><br />
					{'Receiving '}<b>{'psychological support'}</b>{' can help with your mental health and have a positive impact on your quality of life.'}
				</span>
			),
			bedroom_mirror: (
				<span>
					<a href={'/glossary'}><u>{'Acromegaly'}</u></a>{' is a disease caused by an excessive secretion of '}<a href={'/glossary'}><u>{'growth hormone'}</u></a>{', and is characterized by a '}<b>{'growth of the face.'}</b><br /><br />

					{'This growth is more or less important depending on whether the disease occurs before or after puberty.'}<br />
					{'Before puberty, it is then called '}<a href={'/glossary'}><u>{'gigantism'}</u></a>{'.'}<br /><br />

					{'For adults, the increase in facial volume is gradual, and the person may find that their hats or helmets are too small. This may lead to a diagnosis of the disease.'}<br /><br />

					{'It is common to observe a '}<b>{'coarsening of the facial features'}</b>{' in people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'. This usually occurs through forehead wrinkles, prominent brow bone, enlargement of the nose and ears, thickening of the lips, skin wrinkles and nose folds, and prognathism of the lower or upper jaw, or both jaws which can lead to tooth spacing.'}<br /><br />

					{'In most cases, '}<b>{'treatment will limit the evolution of the symptoms.'}</b><br /><br />

					{'Physical changes can lead to decrease in self-esteem.'}<br />
					{'A mental health professional can be a resource to help with acceptance of changes.'}

				</span>
			),
			bedroom_pillow: (
				<span>
					<b>{'Excessive sweating'}</b>{' can also be a clue for the diagnosis of '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'.'}<br /><br />

					{'In some cases, treatment of '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' can help regulate sweating.'}<br /><br />

					{'Here are some tips on how to manage night sweats:'}<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Choose lighter bed linen.'}
						</div>
					</div>
					<div className={'listText'}>
						<div />
						<div>
							{'Lower the temperature of the room before bedtime.'}
						</div>
					</div>
					<div className={'listText'}>
						<div />
						<div>
							{'Use a fan.'}
						</div>
					</div>
					<div className={'listText'}>
						<div />
						<div>
							{'Take a warm shower before going to sleep to help the body regulate its temperature. '}
						</div>
					</div><br />
					{'Finding the right solution to keep yourself cool at night will help you get a better night\'s sleep.'}

				</span>
			),
			bedroom_rings: (
				<span>
					{'A person living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' will/may notice an '}<b>{'enlargement of the hands and fingers.'}</b><br /><br />

					{'For example, it becomes difficult to remove rings.'}<br />
					{'This '}<b>{'may lead to a diagnosis'}</b>{' of the disease.'}
				</span>
			),
			bedroom_bed: (
				<span>
					{'In the majority of cases, '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' is caused by a tumour located in or attached to, the '}<a href={'/glossary'}><u>{'pituitary gland'}</u></a>{'.'}<br />
					{'This gland secretes many different hormones, some of which have a direct '}<b>{'effect on the sexual organs.'}</b><br /><br />

					{'This can explain why some people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' may experience a '}<b>{'decrease in libido'}</b>{' and '}<b>{'erectile dysfunction'}</b>{' in men.'}<br /><br />

					{'Currently, it is uncertain whether erectile dysfunction and decreased libido are direct and proven consequences of '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'.'}
				</span>
			),
			bedroom_boxPaper: (
				<span>
					{'Many people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' may feel that the '}<b>{'changes in their mood'}</b>{' and '}<b>{'behavior'}</b>{' impact their daily lives and cause '}<b>{'feelings of distress.'}</b><br /><br />

					<b>{'Depressive symptoms'}</b>{' and '}<b>{'anxiety'}</b>{' are important factors in the quality of life. These symptoms can be '}<b>{'treated'}</b>{' with appropriate support, but are often underdiagnosed.'}<br /><br />

					{'When interviewed, people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' expressed that mental health problems are the most important factors affecting their '}<b>{'quality of life.'}</b><sup>{'5'}</sup>{' This is why it is important to place greater emphasis on the role of '}<b>{'mental health'}</b>{' when discussing the treatment of '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'.'}
				</span>
			),
			bathroom_toothbrush: (
				<span>
					<a href={'/glossary'}><u>{'Acromegaly'}</u></a>{' causes various alterations to the mouth.'}<br />
					{'The '}<b>{'growth of the jaw'}</b>{' will continue slowly and progressively. The lower jaw may move forward to cause what is called '}<a href={'/glossary'}><u>{'prognathism'}</u></a>{'.'}<br /><br />

					{'The '}<b>{'lips and tongue may become larger'}</b>{', the palate softer. All these changes cause '}<b>{'spacing between the teeth'}</b>{', loosening of teeth and receding of the gum lines.'}<br /><br />

					{'These symptoms in the mouth could lead to the diagnosis of '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'.'}
				</span>
			),
			bathroom_hairbrush: (
				<span>
					{'An excessively high level of '}<a href={'/glossary'}><u>{'growth hormone'}</u></a>{' causes an '}<b>{'increase in hair growth'}</b>{' (moustache, beard, head and body hair).'}<br /><br />

					{'However, '}<b>{'treatments for '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' (drugs and radiotherapy) could cause hair loss'}</b>{' and a general decrease in hair growth.'}<br /><br />

					{'Patients can experience an increase in hair loss after surgery.'}
				</span>
			),
			bathroom_weightScale: (
				<span>
					{'As many parts of the body continue to grow, it is very common for a person living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' to '}<b>{'gain weight'}</b>{'.'}<br /><br />

					{'Lack of physical exercise due to fatigue and joint problems can lead to weight gain as well.'}
				</span>
			),
			bathroom_toiletBag: (
				<span>
					<b>{'Menstrual irregularity'}</b>{' is a common symptom in women with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{', occurring in '}<b>{'40-84% of cases'}</b>{'.'}<sup>{'6'}</sup><br /><br />

					{'High levels of growth hormone and low levels of '}<b><a href={'/glossary'}><u>{'estrogen'}</u></a></b>{' can cause this symptom. In some cases, this can lead to a '}<b>{'lack of menstruation.'}</b>
				</span>
			),
			bathroom_facialCream: (
				<span>
					{'People living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' can experience '}<b>{'skin issues'}</b>{' that can be a source of major distress and concern, such as :'}
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							<b>{'pigmented skin tags'}</b>{' over the trunk are a common finding reported in up to 45% of people with the disease'}<sup>{'7'}</sup>{','}<br />
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							<b>{'oily skin with large pores'}</b>{' due to an over-secretion of sebaceous glands is also major in most people,'}<br />
						</div>
					</div>
					<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							<b>{'skin thickening'}</b>{' and '}<b>{'puffiness'}</b>{' are mainly noticed in the face, hands, and feet.'}
						</div>
					</div>
					<br /><br />
					{'All these symptoms are caused by elevated levels of '}<a href={'/glossary'}><u>{'growth hormone'}</u></a>{' (GH) and '}<a href={'/glossary'}><u>{'insulin growth factor-1'}</u></a>{' (IGF-1). Controlling the over-secretion of these hormones heals most skin issues of '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' and skin appearance may rapidly be improved. However, if the progression of the disease has not yet stopped, some dermatologic manifestations may continue to advance or regress.'}<br /><br />

					{'It is important to '}<b>{'share all your skin changes'}</b>{' with your doctor. Bringing these symptoms to your doctor’s attention can contribute to early diagnosis and thus treatment initiation.'}
				</span>
			),
			garage_bicycle: (
				<span>
					{'The excessive production of '}<a href={'/glossary'}><u>{'growth hormone'}</u></a>{' can lead to '}<b>{'back pain, various joint pains'}</b>{' (this symptom affects 2/3 people living with acromegaly), '}<b>{'bone malformations and chronic fatigue.'}</b><br /><br />

					{'Therefore, it can be difficult to keep practicing regular physical activity.'}<br /><br />

					{'Motivation can also sometimes be lacking with all the changes that a person living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' has to cope with.'}<br /><br />

					{'Sports that are not too demanding, such as '}<b>{'swimming, walking or cycling'}</b>{', can be prioritized.'}
				</span>
			),
			garage_shoes: (
				<span>
					{'Production of excess '}<a href={'/glossary'}><u>{'growth hormone'}</u></a>{' causes both '}<b>{'soft tissue swelling and bone growth.'}</b><br /><br />

					{'These symptoms are often '}<b>{'warning signs'}</b>{' when the disease is diagnosed.'}
				</span>
			),
			garage_microphone: (
				<span>
					{'People living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' may also experience '}<b>{'changes in voice.'}</b><br />
					{'It may sound lower and hoarser.'}<br /><br />

					{'This is due to the '}<b>{'thickening of the '}<a href={'/glossary'}><u>{'larynx'}</u></a></b>{' (one of the respiratory system\'s ducts) which contains the vocal cords.'}
				</span>
			),
			garage_weigh: (
				<span>
					<b>{'Muscular weakness'}</b>{' and '}<b>{'pain'}</b>{' represent one of the significant symptoms in '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{', impacting well-being.'}<br /><br />

					{'Both hormones '}<a href={'/glossary'}><u>{'GH/IGF-1'}</u></a>{' act in the muscle, modifying its function and structure. This explains why the loss of strength has been described in people living with active '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'. Extensive disease duration can also impact muscle strength and balance.'}<br /><br />

					{'The relation between muscle size and function is non-proportional. Indeed, people suffering from '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' can observe increasing strength way longer after muscle growth.'}<sup>{'8'}</sup>{' Hence, '}<b>{'knee extension'}</b>{' and '}<b>{'flexion strength'}</b>{' should improve after 2 years of treatment. But only a few months of treatment is needed for '}<b>{'muscle volume'}</b>{' to increase. '}<b>{'Hand grip'}</b>{' strength can also get better after treatment.'}<br /><br />

					<b>{'Home rehabilitation programs'}</b>{' can improve muscle function, general fatigue, and body balance. These programs offer additional practices to the treatment which may release pain and improve muscular strength.'}
				</span>
			),
			garage_knee: (
				<span>
					{'The two hormones that cause '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' also cause '}<b><a href={'/glossary'}><u>{'joint abnormalities'}</u></a></b>{', resulting in a limited range of motion. If the '}<a href={'/glossary'}><u>{'GH'}</u></a>{' level is not regulated early enough (due to a late diagnosis for example), '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' may cause similar effects as a degenerative joint disease. In this late phase, the damage may become independent of '}<a href={'/glossary'}><u>{'GH'}</u></a>{' and therefore irreversible. In this case, treatment of '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' can only have a limited impact on joint symptoms.'}<br /><br />

					{'When nonsurgical treatments are no longer helpful for relieving pain, '}<b>{'shoulder, hip'}</b>{' and '}<b>{'knee joint replacement surgeries'}</b>{' may be required. In this case, the damaged parts of the joints would be replaced with artificial components, called a '}<b><a href={'/glossary'}><u>{'prosthesis'}</u></a></b>{'. Your family physician may refer you to an orthopedic who can determine with a thorough evaluation whether surgery may be necessary or not to '}<b>{'relieve the pain'}</b>{' and '}<b>{'restore the range of motion'}</b>{'.'}<br /><br />

					{'Following a joint replacement surgery, people may wear a '}<b>{'brace'}</b>{' for a few weeks to support and protect the shoulder or the knee.'}
				</span>
			)
		},
		animated: {
			kitchen: (
				<span>
					<b>{'Explore the changes in the body related to diabetes:'}</b><br /><br />
					<div className={'listText'}>
						<div />
						<div>
							<a href={'/glossary'}><u>{'Growth hormone'}</u></a>{' induces the release of sugar and fat into the blood vessels.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Insulin, a hormone that regulates blood sugar levels, becomes ineffective in combating diabetes induced by '}<a href={'/glossary'}><u>{'growth hormone'}</u></a>{'.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'People living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' tend to develop '}<a href={'/glossary'}><u>{'type-2 diabetes'}</u></a>{' at a younger age than the general population.'}
						</div>
					</div>
				</span>
			),
			kitchen_harm: (
				<span>
					<div className={'listText'}>
						<div />
						<div>
							<a href={'/glossary'}><u>{'Acromegaly'}</u></a>{' can lead to high levels of fat in the blood.'}
						</div>
					</div><br />
					<div className={'listText'}>
						<div />
						<div>
							{'If not managed, this can cause deposits to appear in the arteries, which can block them.'}<br />
							{'This is called atherosclerosis.'}
						</div>
					</div><br />
					<div className={'listText'}>
						<div />
						<div>
							{'This symptom occurs in 19% to 56% of people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'.'}<sup>{'4'}</sup>
						</div>
					</div>
				</span>
			),
			kitchen_heart: (
				<span>
					<div className={'listText'}>
						<div />
						<div>
							{'People living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' and diabetes are more likely to develop high blood pressure.'}
						</div>
					</div><br />
					<div className={'listText'}>
						<div />
						<div>
							{'And diabetes can cause worsening of '}<a href={'/glossary'}><u>{'cardiomyopathy'}</u></a>{'.'}
						</div>
					</div>
				</span>
			),
			bedroom: (
				<span>
					<a href={'/glossary'}><u>{'Acromegaly'}</u></a>{' causes various alterations to the mouth:'}<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'Increased and thickened uvula.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Thickened and '}<a href={'/glossary'}><u>{'everted'}</u></a>{' lower lip.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'An enlarged tongue ('}<a href={'/glossary'}><u>{'macroglossia'}</u></a>{').'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Advanced lower jaw ('}<a href={'/glossary'}><u>{'prognathism'}</u></a>{').'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Obstructed airway.'}
						</div>
					</div>
					<br />
				</span>
			),
			bathroom: (
				<span>
					<a href={'/glossary'}><u>{'Acromegaly'}</u></a>{' can lead to different symptoms affecting the joints:'}<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'Cartilage thickens.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Enlargement of the joints.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Hypomobility of the joints.'}
						</div>
					</div>
					<br /><br />

					{'Osteoarticular symptoms of '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{' are very common and may be the first to appear in a large proportion of people living with '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'.'}<br /><br />

					<b><a href={'/glossary'}><u>{'Osteoarthritis'}</u></a></b>{', the most frequent form of '}<b><a href={'/glossary'}><u>{'arthritis'}</u></a></b>{', is common in people suffering from '}<a href={'/glossary'}><u>{'acromegaly'}</u></a>{'. It occurs most often in the '}<b>{'hands, hips, shoulders'}</b>{' and '}<b>{'knees'}</b>{'.'}<br /><br />

					{'It usually develops slowly and can worsen over time. '}<a href={'/glossary'}><u>{'Osteoarthritis'}</u></a>{' can cause pain, stiffness, and swelling. In some cases, it can also lead to reduced activity and disability.'}<br /><br />

					{'Early diagnosis of '}<a href={'/glossary'}><u>{'arthropathy'}</u></a>{' and appropriate treatment can reduce serious joint complications.'}
				</span>
			),
			garage: (
				<span>
					<a href={'/glossary'}><u>{'Acromegaly'}</u></a>{' can affect the heart:'}<br /><br />
					<div className={'listText'}>
						<div />
						<div>
							{'Increased heart size.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Increased cardiac wall thickness.'}
						</div>
					</div>
					<br />
					<div className={'listText'}>
						<div />
						<div>
							{'Heart rhythm disorders.'}
						</div>
					</div>
				</span>
			)
		},
		button: {
			title:		'LEARN MORE ABOUT',
			kitchen:	'Diabetes',
			bedroom:	'Sleep Apnea',
			bathroom:	'Arthropathy',
			garage:		'Cardiomyopathy',

			switch: {
				bathroom: 'X-ray',
				garage: 'Inside view',
				default:	'Anatomical view'
			}
		},

		next:	'Go to the next room',
		swipe:	'Swipe to the left'
	},

	glossary: {
		title:		'GLOSSARY',
		text: (
			<span>
				<b>{'Acromegaly:'}</b>{' is a hormonal imbalance often caused by a tumour in the hypophysis which induces an abnormal enlargement of the hands, of the feet and of the face among other symptoms.'}<br /><br />
				<b>{'A1C test:'}</b>{' is a simple blood test that measures your average blood sugar levels over the past 3 months.'}<br /><br />
				<b>{'Arthritis:'}</b>{' The term arthritis is used to describe more than 100 conditions that affect joints, the tissues which surround joints, and other connective tissue. Typically, arthritis conditions are characterized by pain and stiffness in and around one or more joints. However, the pattern, severity and location of symptoms vary depending on the specific form of the disease. Symptoms can develop gradually or suddenly.'}<br /><br />
				<b>{'Arthropathy:'}</b>{' is a joint disease that reduces the range of motion and bone erosion and causes joint pain.'}<br /><br />
				<b>{'Cardiomyopathy:'}</b>{' is a disease of the heart muscle that prevents the heart from pumping blood to the rest of the body.'}<br /><br />
				<b>{'Carpal tunnel:'}</b>{' is a space in the wrist surrounded by wrist bones and by a rigid ligament that links the bones together. Through this small tunnel pass the flexing tendons of the fingers and thumb as well as the median nerve. These tendons attach muscles to bones in the hand and transfer the movement of the fingers from muscles to bones. The median nerve carries signals from the brain to control the actions of the fingers and hand. It also carries information about temperature, pain and touch from the hand to the brain, and controls the sweating of the hand.'}<br /><br />
				<b>{'Dysmorphia:'}</b>{' is the medical term that implies an abnormal shape of an organ or a body part.'}<br /><br />
				<b>{'Evert:'}</b>{' to turn inside out or outward.'}<br /><br />
				<b>{'Endorphins:'}</b>{' hormones released by the hypothalamus and pituitary gland in response to pain or stress. This group of hormones relieves pain and creates a general feeling of well-being. Endorphins are the body’s natural painkillers.'}<br /><br />
				<b>{'Estrogen:'}</b>{' category of sexual hormone involved in the development and regulation of the female sexual organs and in the development of secondary sexual characteristics in women.'}<br /><br />
				<b>{'GH:'}</b>{' is a hormone (HGH for humans) that stimulates cell reproduction/regeneration and growth.'}<br /><br />
				<b>{'Gigantism:'}</b>{' is abnormal growth due to an excess of growth hormone  (GH) during childhood.'}<br /><br />
				<b>{'Hypophysis:'}</b>{' is an endocrine gland situated at the bottom of the brain and behind the sinuses. It produces a wide variety of hormones like the GH.'}<br /><br />
				<b>{'IGF-1 :'}</b>{' Insulin Glucose Factor - 1'}<br /><br />
				<b>{'Joint abnormalities:'}</b>{' abnormal structure or form of the joints.'}<br /><br />
				<b>{'Larynx:'}</b>{' organ located in the throat. It accomplishes a variety of functions such as: creating sound (voice) and separating the direction of food and air to their respective organ.'}<br /><br />
				<b>{'Macroglossia:'}</b>{' abnormal enlargement of the tongue.'}<br /><br />
				<b>{'OGTT:'}</b>{' Oral Glucose Tolerance Test.'}<br /><br />
				<b>{'Osteoarthritis:'}</b>{' is a progressive disease of the whole joint and most commonly affects the joints of the hands, feet, hips, knees, shoulders and spine, though almost any joint can be affected.Osteoarthritis is the most common type of arthritis and a significant cause of disability worldwide.'}<br /><br />
				<b>{'Polyuria:'}</b>{' excessive production of urine.'}<br /><br />
				<b>{'Prognathism:'}</b>{' forward projection of the upper or lower jaw causing a misalignment of the teeth and of the mouth.'}<br /><br />
				<b>{'Prosthesis:'}</b>{' a device designed to replace a missing body part or to enable a body part to work better.'}<br /><br />
				<b>{'Secondary hyperlipidemia:'}</b>{' is an abnormally high amount of lipids in the bloodstream induced by non-genetic factors, such as the environment.'}<br /><br />
				<b>{'Sleep Apnea:'}</b>{' sleep disorder causing apnea (cessation of respiratory flow/stop breathing) or decreased respiratory flow.'}<br /><br />
				<b>{'Tumour:'}</b>{' group of abnormal cells, expanding uncontrollably and creating a lump.'}<br /><br />
				<b>{'Type-2-Diabetes:'}</b>{' a chronic disease in which the blood glucose levels are too high due to insufficient production or misuse of insulin.'}<br /><br />
			</span>
		)
	},

	contact: {
		title:	'CONTACT US',
		text: (
			<span>
				{'Acromegaly Canada works to raise awareness through education while providing a network of support for patients and their families across Canada.'}<br /><br />
				<a href={'mailto:contact@acromegalycanada.ca'}>{'contact@acromegalycanada.ca'}</a><br /><br />
				<a href={'https://acromegalycanada.ca/'} target={'_blank'} rel={'noreferrer'}>{'https://acromegalycanada.ca/'}</a><br /><br />
			</span>
		),
		thanks:	'Acromegaly Canada thanks these organizations for supporting the AcrHOMEgaly project.'
	},

	reference: {
		title:	'REFERENCES',
		text: (
			<div>
				<div className={'listText ref ref1'}>
					<div>{'1.'}</div>
					<div>
						{'Anderson D, Faber P, Marcovitz S, Hardy J, Lorenzetti D. Pituitary tumors and the ophthalmologist. Ophthalmology. 1983 Nov;90(11):1265-70. doi: 10.1016/s0161-6420(83)34393-1. PMID: 6664664.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref2'}>
					<div>{'2.'}</div>
					<div>
						{'Vitale G, Pivonello R, Auriemma RS, et al. . Hypertension in acromegaly and in the normal population: prevalence and determinants. Clin Endocrinol (Oxf). 2005;63:470–476.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref3'}>
					<div>{'3.'}</div>
					<div>
						{'Geer, E.B., Sisco, J., Adelman, D.T. et al. Observed discordance between outcomes reported by acromegaly patients and their treating endocrinology medical provider. Pituitary 23, 140–148 (2020).'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref4'}>
					<div>{'4.'}</div>
					<div>
						{'Mazziotti, G. et al. Influence of diabetes mellitus on vertebral fractures in men with acromegaly. Endocrine. 2011 Aug.40(1):102-8.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref5'}>
					<div>{'5.'}</div>
					<div>
						{'Geraedts VJ, Dimopoulou C, Auer M, Schopohl J, Stalla GK, Sievers C. Health Outcomes in Acromegaly: Depression and Anxiety are Promising Targets for Improving Reduced Quality of Life. Front Endocrinol (Lausanne). 2015 Jan 6;5:229. doi: 10.3389/fendo.2014.00229. PMID: 25610427; PMCID: PMC4285111.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref6'}>
					<div>{'6.'}</div>
					<div>
						{'Kaltsas GA, Mukherjee JJ, Jenkins PJ, Satta MA, Islam N, Monson JP, Besser GM, Grossman AB. Menstrual irregularity in women with acromegaly. J Clin Endocrinol Metab. 1999 Aug;84(8):2731-5. doi: 10.1210/jcem.84.8.5858. PMID: 10443669.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref7'}>
					<div>{'7.'}</div>
					<div>
						{'Ben-Shlomo A, Melmed S. Skin manifestations in acromegaly. Clin Dermatol. 2006 Jul-Aug;24(4):256-9. doi: 10.1016/j.clindermatol.2006.04.011. PMID: 16828406.'}
					</div>
				</div>
				<br /><br />
				<div className={'listText ref ref8'}>
					<div>{'8.'}</div>
					<div>
						{'Füchtbauer L, Olsson DS, Bengtsson BÅ, Norrman LL, Sunnerhagen KS, Johannsson G. Muscle strength in patients with acromegaly at diagnosis and during long-term follow-up. Eur J Endocrinol. 2017 Aug;177(2):217-226. doi: 10.1530/EJE-17-0120. Epub 2017 May 31. PMID: 28566445.'}
					</div>
				</div>
			</div>
		)
	}
}

export default text